
import React from 'react'
import { withRouter, Link } from 'react-router-dom';
import utility from '../../commons/utility.js';
import ajax from '../../commons/agent.js';
import ErrorMessage from '../../commons/errorMessage.jsx';
import { Button, Input, Icon, Grid, Breadcrumb, Statistic, Segment, Dropdown, Form } from 'semantic-ui-react';
import DmpNavbar from '../dmpNavbar.jsx';
import AdvSearch from './AdvSearch.jsx';
import HeatMap from '../../commons/geomap/HeatMap.jsx';
import BarChart from '../../commons/d3/BarChart.jsx';
import PieChart from '../../commons/d3/PieChart.jsx';
import { truncate } from 'lodash'
import PageHeader from '../PageHeader.jsx';
import DatePicker from "react-datepicker";
import moment from 'moment'

class DcnDashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			errorResponse: [],
			loader: false,
			data: null,
			isFetchingOrganization: false,
			councilOptions: [],		

			selectedCouncils: ['All Local Authorities'],
			selectedCouncilsQueried: ['All Local Authorities'],

			data: [],
			localCouncilNameAggregation: [],
			dogSexAggregation: [],
			dcnStatusAggregation: [],
			dogBreedAggregation: [],

			dcnEffectiveDateGte: '',
			dcnEffectiveDateLte: '',
		};
	}

	componentDidMount() {
		this.pageReload();
	}

	// componentDidUpdate(prevProps, prevState) {
	//     if (prevProps.location !== this.props.location) {
	//         this.pageReload();
	//     }
	// }

	pageReload() {
		var self = this
		const orgList = this.props.fetchOrganizationList()
		const myBpid = utility.fetchUserBpid()
		this.setState({
			// orgList: orgList,
			councilOptions: orgList.filter(item => (item.organizationCategory === 'Local Authority')).map(item => ({ key: item.organizationBpid, text: `${item.organizationName}`, value: item.organizationName }))
		})
		this.getData()
	}

	getData = () => {
		var self = this
		utility.enterLoading(self);

		let query = {
			"size": 10000,
			"_source": [
				"dcnId",
				"xCoordinate",
				"yCoordinate",
				"dcnStatus",
				"dcnReferenceNo", "dcnEffectiveDate", "issuerUserId", "issuerForename", "issuerSurname", "dogMicrochipCode", "dogBreed", "dogName", "dogSex", "dogNeutered", "ppForename", "ppSurname", "ppDob", "ppAddressLine1", "ppAddressLine2", "ppCity", "ppPostCode", "ppUprn"
			],
			"query": {
				"bool": {
					"filter": [],
					"must": [{ "terms": { "dcnStatus.keyword": ['Active', 'Discharged'] } }]
				}
			},
			"aggs": {
				"localCouncilName": {
					"terms": { "field": "localCouncilName.keyword", "size": 10000 }
				},
				"dogSex": {
					"terms": { "field": "dogSex.keyword", "size": 10000 }
				},
				"dcnStatus": {
					"terms": { "field": "dcnStatus.keyword", "size": 10000 }
				},
				"dogBreed": {
					"terms": { "field": "dogBreed.keyword", "size": 10000 }
				}			
			}
		}

		if (this.state.dcnEffectiveDateGte && this.state.dcnEffectiveDateLte) query.query.bool.filter = [...query.query.bool.filter, { "range": { "dcnEffectiveDate": { "gte": moment.utc(this.state.dcnEffectiveDateGte).format("YYYY-MM-DD") + "T00:00:00.000Z", "lte": moment.utc(this.state.dcnEffectiveDateLte).format("YYYY-MM-DD") + "T23:59:59.000Z" } } }]
		if (this.state.dcnEffectiveDateGte && !this.state.dcnEffectiveDateLte) query.query.bool.filter = [...query.query.bool.filter, { "range": { "dcnEffectiveDate": { "gte": moment.utc(this.state.dcnEffectiveDateGte).format("YYYY-MM-DD") + "T00:00:00.000Z" } } }]
		if (!this.state.dcnEffectiveDateGte && this.state.dcnEffectiveDateLte) query.query.bool.filter = [...query.query.bool.filter, { "range": { "dcnEffectiveDate": { "lte": moment.utc(this.state.dcnEffectiveDateLte).format("YYYY-MM-DD") + "T23:59:59.000Z" } } }]


		if (!this.state.selectedCouncils.includes('All Local Authorities')) {
			query.query.bool.must = [
				...query.query.bool.must,
				{ "terms": { "localCouncilName.keyword": [...this.state.selectedCouncils] } }
			]
		}

		// console.log(query)
		ajax.ElasticDcn.elasticdogcontrolnotice(query).end((err, response) => {
			utility.enterUnLoading(self);
			let temp = utility.handleErrors(err);
			if (!err && response) {

				// let data = response.body.hits.hits.reduce((acc, item) => acc = [...acc, { ...item._source, x: 287561, y: 693618 }], [])
				let data = response.body.hits.hits.reduce((acc, item) => acc = [...acc, { ...item._source, x: parseInt(item._source.xCoordinate || 0), y: parseInt(item._source.yCoordinate || 0) }], [])
				let locationObj = {}
				data.forEach(item => {
					locationObj[item.x + '-' + item.y] = [...locationObj[item.x + '-' + item.y] || [], item]
				})
				let locationData = Object.keys(locationObj).map((index, key) => ({
					x: locationObj[index][0].x,
					y: locationObj[index][0].y,
					dcnList: locationObj[index]
				}))
				self.setState({
					data,
					locationData,
					selectedCouncilsQueried: this.state.selectedCouncils,
					localCouncilNameAggregation: response.body.aggregations.localCouncilName.buckets,
					dogSexAggregation: response.body.aggregations.dogSex.buckets,
					dcnStatusAggregation: response.body.aggregations.dcnStatus.buckets,
					dogBreedAggregation: response.body.aggregations.dogBreed.buckets,
				},
					() => {
						// console.log(this.state)
						// this.setState(prevState => {
						//     prevState.productData.map(item => {
						//         item.createdAt = item.createdAt && moment.utc(item.createdAt, 'DD-MM-YYYY HH:mm:ss').local().format("YYYY-MM-DD HH:mm")

						//     })
						// })
					}
				);
			}
			else {
				this.setState({ errorResponse: temp });
			}
		})
	}

	handleCouncilChange = (e, { value }) => {
		// console.log(value)
		let selectedCouncils = value

		if (value.length === 0)
			selectedCouncils = ['All Local Authorities']
		if (value.indexOf('All Local Authorities') === 0)
			selectedCouncils = value.filter(item => item !== 'All Local Authorities')
		if (value.indexOf('All Local Authorities') === value.length - 1)
			selectedCouncils = ['All Local Authorities']

		this.setState({ selectedCouncils })

	}

	reset = () => {
		this.setState(
			{
				dcnEffectiveDateGte: '',
				dcnEffectiveDateLte: '',
				selectedCouncils: ['All Local Authorities']
			},
			() => this.getData()
		)
	}

	render() {
		return (
			<>
				<PageHeader />
				{utility.ShowLoadingV2(this)}
				{this.state.errorResponse.errFlag === 'Y' &&
					<Grid className="mx-0 px-0">
						<Grid.Column width={16} className="m-0 p-0">
							<ErrorMessage showServerError={this.state.errorResponse} />
						</Grid.Column>
					</Grid>
				}

				{(this.state.errorResponse.errFlag !== 'Y' && !this.state.loader) &&
					<div className='px-0 mx-0'>
						<Grid columns='equal' reversed='mobile vertically' className="mx-0 p-1 py-3" style={{ background: '#ebb209 url("/images/bg_dog.png") center fixed', backgroundSize: 'cover' }}>
							<Grid.Column className="px-1 py-4" verticalAlign='middle' mobile={16} tablet={10} computer={8}>
								<div className='textBigger color-fff textBold'>Hi {utility.fetchUserFirstName()}, looking for a DCN?</div>
								<p className='textMedium color-fff py-1'>The Scottish Dog Control National Database allows you to  search for any Dog Control Notices, by dog or proper person's name, an address, a microchip number, or a keyword</p>
								<Grid columns='equal'>
									<Grid.Column mobile={16} tablet={8} computer={8}>
										<Button icon='search' content='Search a DCN' onClick={() => this.props.history.push("/dashboard/dogControlNotices")} size='big' fluid className='customBtn1Primary px-5px' />
									</Grid.Column>
									{/* <Grid.Column>
                                        <Button icon='plus' content='Upload a DCN' onClick={() => this.props.history.push("/dashboard/uploadDcn")} size='big' fluid className='customBtn1Secondary px-5px' />
                                    </Grid.Column> */}
								</Grid>
							</Grid.Column >
							<Grid.Column only='computer tablet'>

							</Grid.Column>
						</Grid>




						<Grid columns='equal' className='bgPrimaryDark py-0 pt-1 px-1 m-0'>
							<Grid.Column mobile={16} tablet={12} computer={12}>
								<div className='textBigger color-fff'>Dog Control Notices at a Glance</div>
							</Grid.Column>
							<Grid.Column textAlign='right'>
							</Grid.Column>
						</Grid>

						<Grid columns={3} className='bgPrimaryDark py-0 px-1 m-0'>
							<Grid.Column mobile={16} tablet={16} computer={8} textAlign='left'>
								<Dropdown placeholder='Local Authority'
									fluid multiple selection
									loading={this.state.isFetchingOrganization}
									options={[{ key: 'All Local Authorities', text: 'All Local Authorities', value: 'All Local Authorities' }, ...this.state.councilOptions]}
									value={this.state.selectedCouncils}
									onChange={this.handleCouncilChange}
								/>
							</Grid.Column>
							<Grid.Column mobile={16} tablet={16} computer={5} textAlign='left'>
								<Grid columns='equal' verticalAlign='middle'>
									<Grid.Column className='pr-0'>
										{/* <Input type="date" fluid value={this.state.dcnEffectiveDateGte} onChange={(e) => { this.setState({ dcnEffectiveDateGte: e.target.value }) }} /> */}
										<Form>
											<Form.Field>
												<div className="ui left icon input">
													<DatePicker
														id='dcnEffectiveDateGte' placeholderText={`DD/MM/YYYY`} selected={this.state.dcnEffectiveDateGte || null}
														onChange={(date) => this.setState({ dcnEffectiveDateGte: date })}
														dateFormat="dd/MM/yyyy" className="react-dp-custom"
														isClearable
														strictParsing
													/>
													<i aria-hidden="true" className="calendar outline icon"></i>
												</div>
											</Form.Field>
										</Form>
									</Grid.Column >
									<Grid.Column textAlign='center' width={1} className='color-fff px-5px'>
										to
									</Grid.Column>
									<Grid.Column className='pl-0'>
										{/* <Input type="date" fluid value={this.state.dcnEffectiveDateLte} onChange={(e) => { this.setState({ dcnEffectiveDateLte: e.target.value }) }} /> */}
										<Form>
											<Form.Field>
												<div className="ui left icon input">
													<DatePicker
														id='dcnEffectiveDateLte' placeholderText={`DD/MM/YYYY`} selected={this.state.dcnEffectiveDateLte || null}
														onChange={(date) => this.setState({ dcnEffectiveDateLte: date })}
														dateFormat="dd/MM/yyyy" className="react-dp-custom"
														isClearable
														strictParsing
													/>
													<i aria-hidden="true" className="calendar outline icon"></i>
												</div>
											</Form.Field>
										</Form>
									</Grid.Column>
								</Grid>

							</Grid.Column>
							<Grid.Column mobile={8} tablet={8} computer={1} textAlign='left' className='pr-0'>
								<Button content='Reset' loading={this.state.loader} onClick={() => this.reset()} size='big' fluid className='customBtn1Secondary px-5px textNormal' />
							</Grid.Column>
							<Grid.Column mobile={8} tablet={8} computer={2} textAlign='left' className='pl-5px'>
								<Button content='Apply Filter' loading={this.state.loader} onClick={() => this.getData()} size='big' fluid className='customBtn1Primary px-5px textNormal' />
							</Grid.Column>
						</Grid>

						<Grid columns='equal' className='bgPrimaryDark py-0 px-1 m-0'>
							<Grid.Column stretched mobile={16} tablet={8} computer={8}>
								<Segment className='p-1' textAlign='center'>
									<div className='colPrimary textBiggest'>{this.state.dcnStatusAggregation.filter(item => item.key === 'Active')[0] ? this.state.dcnStatusAggregation.filter(item => item.key === 'Active')[0].doc_count : '0'}</div>
									<Button compact color='green'>ACTIVE</Button>
									<div className='textNormal'>Dog Control Notices in {this.state.selectedCouncilsQueried.join(', ')}</div>
								</Segment>

							</Grid.Column>
							<Grid.Column stretched>
								<Segment className='p-1' textAlign='center'>
									<div className='colPrimary textBiggest'>{this.state.dcnStatusAggregation.filter(item => item.key === 'Discharged')[0] ? this.state.dcnStatusAggregation.filter(item => item.key === 'Discharged')[0].doc_count : '0'}</div>
									<Button compact color='grey'>DISCHARGED</Button>
									<div className='textNormal'>Dog Control Notices in {this.state.selectedCouncilsQueried.join(', ')}</div>
								</Segment>
							</Grid.Column>
						</Grid>
						<Grid columns='equal' className='bgPrimaryDark py-0 px-1 m-0'>
							<Grid.Column>
								<Segment>
									<div className='textBig py-1 pb-2  textBold text-center p-1'>DCN Hot Spots</div>
									<HeatMap data={this.state.locationData} height='560px' width='100%' center={[56.4907, -4.2026]} zoom={4} scrollWheelZoom={true} />
								</Segment>
							</Grid.Column>
						</Grid>

						<Grid columns='equal' className='bgPrimaryDark py-0 px-1 m-0'>
							<Grid.Column stretched textAlign='center' mobile={16} tablet={16} computer={5}>
								<Segment style={{ overflow: 'auto' }}>
									<div className='textBig py-1 pb-2  textBold text-center p-1'>Out of Control Dog Sex Comparison</div>
									{this.state.dogSexAggregation.length > 0 && <BarChart height={360} width={320} data={this.state.dogSexAggregation.map(item => ({ x: `${item.key}(${item.doc_count})`, y: item.doc_count }))} />}
								</Segment>
							</Grid.Column>
							<Grid.Column stretched textAlign='center'>
								<Segment style={{ overflow: 'auto' }}>
									<div className='textBig py-1 pb-2  textBold text-center p-1'>Most Frequent Out of Control Breed</div>
									{this.state.dogBreedAggregation.length > 0 && <BarChart height={360} width={this.state.dogBreedAggregation.slice(0, 5).length * 140} data={this.state.dogBreedAggregation.sort((a, b) => b.doc_count - a.doc_count).slice(0, 5).map(item => ({ x: `${truncate(item.key, { 'length': 12, 'omission': '...' })}(${item.doc_count})`, y: item.doc_count }))} />}
								</Segment>
							</Grid.Column>
						</Grid>

						{/* <Grid columns='equal' className='bgPrimaryDark py-0 px-1 m-0'>
                            <Grid.Column stretched textAlign='center' mobile={16} tablet={16} computer={5}>
                                <Segment style={{ overflow: 'auto' }}>
                                    <div className='textNormal textBold text-center p-1'>Out of Control Dog Sex Comparison</div>
                                    {this.state.dogSexAggregation.length > 0 && <PieChart height={360} width={320} data={this.state.dogSexAggregation.map(item => ({ x: `${item.key}(${item.doc_count})`, y: item.doc_count }))} />}
                                </Segment>
                            </Grid.Column>
                            <Grid.Column stretched textAlign='center'>
                                <Segment style={{ overflow: 'auto' }}>
                                    <div className='textNormal textBold text-center p-1'>Most Frequent Out of Control Breed</div>
                                    {this.state.dogBreedAggregation.length > 0 && <PieChart height={560} width={800} data={this.state.dogBreedAggregation.sort((a, b) => b.doc_count - a.doc_count).map(item => ({ x: `${truncate(item.key, { 'length': 18, 'omission': '...' })}(${item.doc_count})`, y: item.doc_count }))} />}
                                </Segment>
                            </Grid.Column>
                        </Grid> */}



						<Grid columns='equal' className='bgPrimaryDark py-1 m-0'>
							<Grid.Column textAlign='center'>
							</Grid.Column>
							<Grid.Column width={4} textAlign='center'>
								<Button content='Print Report' loading={this.state.loader} onClick={() => window.print()} size='big' fluid className='customBtn1Secondary px-5px' />
							</Grid.Column>
							<Grid.Column textAlign='center'>
							</Grid.Column>
						</Grid>

						<Grid columns='equal' className='bgPrimaryDark py-1 m-0'>
							<Grid.Column>

							</Grid.Column>
						</Grid>



					</div>}

			</>
		);

	}
}

export default withRouter(DcnDashboard);
