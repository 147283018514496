import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Search, Input, Grid } from 'semantic-ui-react'
import ajax from '../../commons/agent.js';
import moment from 'moment'
import { debounce, startCase } from 'lodash'
import utility from '../../commons/utility.js';

export class AdvSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            results: [],
            value: '',
        };
    }
    handleSearchChange = debounce(() => {
        // this.setState({ isLoading: true  });

        let self = this
        let query = {
            "size": 5,
            "_source": ["dcnId", "dogImgLocation", "dcnReferenceNo", "dcnStatus", "createdAt", "dcnEffectiveDate", "dcnReason", "issuerUserId", "issuerForename", "issuerSurname", "issuerContactNo", "issuerAddressLine1", "issuerAddressLine2", "issuerCity", "issuerPostCode", "dogMicrochipCode", "dogBreed", "dogName", "dogSex", "dogIdentificationMark", "dogNeutered", "ppForename", "ppSurname", "ppDob", "ppAddressLine1", "ppAddressLine2", "ppCity", "ppPostCode", "ppUprn"],
            "query": {
                "bool": {
                    "filter": [
                        {
                            "simple_query_string": { "query": `${this.state.value.trim()}${this.state.value.length ? '*' : ''}`, "default_operator": "and" }
                        }
                    ]
                }
            },
            "highlight": {
                "pre_tags": ["#fnd#%bld%"], "post_tags": ["#fnd#"],
                "fields": {
                    "*": {}
                },
                "require_field_match": true
            }
        }
        ajax.ElasticDcn.elasticdogcontrolnotice(query).end((err, response) => {
            let results = []
            if (!err && response) {
                // console.log(response.body.hits.hits)
                if (response.body.hits.total.value > 0) {
                    results = response.body.hits.hits.map(item => {
                        // item.highlight && delete item.highlight['attributeList']
                        // item.highlight && delete item.highlight['logoImageLocation']

                        let taglist = Object.keys(item.highlight).reduce(function (a, k) {
                            return [...a, (`${startCase(k.replace('.keyword', ''))} > ${item.highlight[k]}`)];
                        }, []);
                        taglist = taglist && Array.from(new Set(taglist))
                        taglist.length = taglist && 4
                        let searchdata = { title: item._source.dcnReferenceNo, taglist, dog: { ...item._source } }
                        return searchdata
                    })
                }
            }
            // console.log(results)
            this.setState({ isLoading: false, results });
        })
    }, 360)

    // camelToWords = (text = '') => {
    //     var result = text.replace(/([A-Z])/g, " $1");
    //     return result.charAt(0).toUpperCase() + result.slice(1);
    // }

    resultRenderer = ({ taglist, dog }) => <>
        <div key={dog.dcnReferenceNo} className='flex flexDirectionRow'>
            {/* <div className='mr-1'>
                <img id={dog.dcnReferenceNo} src={`/dogbreeds/${dog.dogImgLocation}`} className='border-1' style={{ "width": "56px", "height": "58px", "objectFit": "cover" }} />
            </div> */}
            <div>
                <div>
                    <span className='colPrimary textBold'>{dog.dcnReferenceNo}</span>
                    <span className='textSmall'>
                        <span className={`ml-1 textBold ${dog.dcnStatus == 'Active' ? 'colMoney' : 'ColGrey20'}`}> {dog.dcnStatus}</span>
                        <span> ∙ {utility.timeAgo(dog.createdAt)}</span>
                    </span>
                </div>
                <div>
                    <span>{dog.dogName}</span>
                    <span> ∙ {dog.dogSex}</span>
                    <span> ∙ {dog.dogBreed}</span>
                    {/* <span> ∙ {dog.localCouncilName}</span> */}
                </div>
                {taglist.map(item => {
                    return (
                        <span style={{ "fontSize": "0.88em", "padding": "1px 1px 1px 4px", "margin": "2px 4px 2px 0px", "display": "inline-block", "color": "#888", "border": "1px solid #ddd", "borderRadius": '2px' }}>
                            {
                                item.split('#fnd#').map((word, key) => {
                                    return word.indexOf("%bld%") === 0 ?
                                        <span className='textBold' style={{ "background": "#fff176", "color": "rgb(41, 41, 41)", "padding": "1px 1px 2px 1px" }}>{`${word.replace('%bld%', '').trim()}`}</span>
                                        :
                                        `${word} `
                                })
                            }
                        </span>
                    )
                })}
            </div>
        </div>
    </>
    render() {
        return (
            <><Grid className="mx-0" columns='equal'>
                <Grid.Row className="mx-0 py-2 bgPrimaryDark">
                    <Grid.Column>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={14} computer={10}>
                        <Search
                            type="text"
                            fluid
                            loading={this.state.isLoading}
                            input={<Input inverted className='border-rad' size='large' style={{ width: '100%', background: '#fff' }} />}
                            onResultSelect={(e, data) =>
                                this.props.history.push(`/dashboard/dogControlNotices/dcnsdetailpage/${data.result.dog.dcnId}`)
                            }
                            onSearchChange={(e, { value }) => { this.setState({ value, isLoading: true }); this.handleSearchChange() }}
                            resultRenderer={this.resultRenderer}
                            results={this.state.results}
                            value={this.state.value}
                            showNoResults={true}
                            placeholder='Search a DCN'
                        />
                        <p className='colWhite customTextMedium text-center mt-5px'>You can enter any address, proper person or dog's name, DCN or microchip number or keyword.</p>
                    </Grid.Column>
                    <Grid.Column>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            </>
        )
    }
}

export default withRouter(AdvSearch)
