import React from 'react'
import { withRouter, Link } from 'react-router-dom';
import utility from '../../commons/utility.js';
import ajax from '../../commons/agent.js';
import { Button, Input, Icon, Loader, Grid, Segment, Label, Modal, Divider } from 'semantic-ui-react';
import { Table } from 'antd';
import moment from 'moment'
import PageHeader from '../PageHeader.jsx';
import { withTranslation } from 'react-i18next';
import DisplayMessage from '../../commons/displayMessage.jsx';
import DcnsDetailPage from './DcnsDetailPage.jsx';

class TransferRequests extends React.Component {

   constructor(props) {
      super(props);
      this.state = {
         messageType: '', messageHeader: '', message: [],
         data: [], dataLength: 0, errorResponse: [],
         filteredInfo: null, sortedInfo: null,
         searchText: '', searchedColumn: '',
         currentDataCount: 0,

         catsubcatFilter: null,
         categoryOptions: null,

         isOpenOrgDetailsModal: false,
         selectedorganizationId: '',
      };
   }

   componentDidMount() {
      this.props.fetchOrganizationCategory(utility.fetchUserBpid()) !== 'Local Authority' && this.props.history.push('/')
      this.pageReload();
   }
   pageReload() {
      const self = this

      this.setState({ isFetching: true })
      ajax.DCNService.fetchTransferDcnList('all').end((err, response) => {
         this.setState({ isFetching: false })
         let temp = utility.handleErrors(err);
         if (!err && response) {
            self.setState({ data: response.body, dataLength: response.body.length },
               () => {
                  this.setState(prevState => {
                     prevState.data.map(item => {
                        item.createdAt = item.createdAt && utility.formatDate(item.createdAt)
                        item.fromOrganizationName = this.props.fetchOrganizationName(item.fromBpid)
                        item.toOrganizationName = this.props.fetchOrganizationName(item.toBpid)
                        // item.sellerOrganizationName = this.props.fetchOrganizationName(item.sellerBpid)
                        // item.pendingWith = item.pendingWithBpid && this.props.fetchOrganizationName(item.pendingWithBpid)
                        // item.status = item.status || ''
                        // item.lastUpdatedAt = item.lastUpdatedAt && utility.formatDate(item.lastUpdatedAt)

                     })
                  })
               });
         }
         else {
            this.setState({ errorResponse: temp });
         }
      })


   }



   //search start
   getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               size='small'
               className='customInput'
               ref={node => {
                  this.searchInput = node;
               }}
               placeholder={`Search`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ marginBottom: 8, display: 'block' }}
            />
            <div >
               <Button icon='redo' content='Reset' size="small" compact className='customBtn1Secondary' style={{ width: '48%' }} onClick={() => this.handleReset(clearFilters)} />
               <Button icon='search' content='Search' size="small" compact className='customBtn1Primary' style={{ width: '50%', marginRight: '0' }} onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)} />
            </div>
         </div>
      ),
      filterIcon: filtered => <Icon name='search' className={filtered ? 'colPrimary' : ''} style={{ padding: '15px 0px' }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      onFilterDropdownVisibleChange: visible => {
         if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
         }
      },
      render: text => text,
   });

   handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      this.setState({
         searchText: selectedKeys[0],
         searchedColumn: dataIndex,
      });
   };

   handleReset = clearFilters => {
      clearFilters();
      this.setState({ searchText: '' });
   };
   //search end 


   handleChange = (pagination, filters, sorter, currentDataSource) => {
      this.setState({
         filteredInfo: filters,
         sortedInfo: sorter,
      });
   };

   clearAll = () => {
      this.setState({
         filteredInfo: null,
         sortedInfo: null,
      });
   };

   //End of Table Function
   handleRemarkChange = (organizationId, value) => {
      let data = [...this.state.data]
      data.map(item => (item.organizationId === organizationId) && (item.remark = value))
      this.setState({ data })

   }

   acceptTransfer = () => {
      let self = this
      let transferId = this.state.acceptConfirmation
      if (transferId) {
         self.setState({ isAccepting: true })
         ajax.DCNService.acceptTransfer(transferId).end((err, response) => {
            self.setState({ acceptConfirmation: false, isAccepting: false })
            let errJson = utility.handleErrors(err);
            if (!err && response) {
               this.pageReload()
               this.setState({ reviewModal: false })
               utility.loadDisplayMessage(self, "success", "Success", []);
            } else {
               utility.loadDisplayMessage(self, "error", "Failure.", errJson.errMessage);
            }
         })
      }
   }
   rejectTransfer = () => {
      let self = this
      let transferId = this.state.rejectConfirmation
      if (transferId) {
         self.setState({ isRejecting: true })
         ajax.DCNService.rejectTransfer(transferId).end((err, response) => {
            self.setState({ rejectConfirmation: false, isRejecting: false })
            let errJson = utility.handleErrors(err);
            if (!err && response) {
               this.pageReload()
               this.setState({ reviewModal: false })
               utility.loadDisplayMessage(self, "success", "Success", []);
            } else {
               utility.loadDisplayMessage(self, "error", "Failure.", errJson.errMessage);
            }
         })
      }
   }

   render() {
      const { t } = this.props
      let { sortedInfo, filteredInfo, pipelineVo } = this.state;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      const cellStyle = { color: 'var(--grey20)', padding: '4px 8px', fontSize: '.98em' }

      const filename = 'DCNs_' + Date.now(),
         fields = {
            "dcnReferenceNo": "DCNREFERENCENO",
            "dogOwnerName": "DOG OWNER NAME",
            "dogOwnerAddress": "DOG OWNER ADDRESS",
            "dogName": "DOG NAME",
            "dogBreed": "DOG BREED",
            "createdAt": "CREATED AT",
            "dcnStatus": "DCN STATUS",
            // "buyerOrganizationName2": "BUYER",
         },
         style = {
            padding: "0px 12px", fontFamily: 'Roboto', fontSize: '0.92em', border: 'none', height: '32px', lineHeight: '30px'
         },
         text = "Download"

      const columns = [
         {
            title: 'Reference No', dataIndex: 'dcnReferenceNo', key: 'dcnReferenceNo',
            // sorter: (a, b) => (a.dcnReferenceNo * a.dcnReferenceNo) - (b.dcnReferenceNo * b.dcnReferenceNo),
            // sortOrder: sortedInfo.columnKey === 'dcnReferenceNo' && sortedInfo.order,
            ...this.getColumnSearchProps('dcnReferenceNo'),
            width: '12%',
            fixed: 'left',
            ellipsis: true,
            render: (dcnReferenceNo, record) => {
               return {
                  props: { style: { ...cellStyle } },
                  children: <>
                     {record.transferStatus === 'Transfer Initiated' ?
                        <Link className='colPrimary textBold' to={`/dashboard/dogControlNotices/dcnsdetailpage/${record.dcnIdNew || record.dcnId}`}>{dcnReferenceNo}</Link>
                        :
                        <span className='textBold'>{dcnReferenceNo}</span>
                     }
                  </>
               }
            }
         },
         {
            title: 'Initiated On', dataIndex: 'createdAt', key: 'createdAt',
            sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
            sortOrder: sortedInfo.columnKey === 'createdAt' && sortedInfo.order,
            width: '12%',
            // fixed: 'left',
            ellipsis: true,
            // render: (createdAt, record) => {
            //    return {
            //       props: { style: { ...cellStyle } },
            //       children: <><Link className='customColPrimary' to={`dcnUser/dcnsdetailpage/${record.contractCode}`}>{dataProductName}</Link></>
            //    }
            // }
         },
         {
            title: 'From', dataIndex: 'fromOrganizationName', key: 'fromOrganizationName',
            sorter: (a, b) => new Date(a.fromOrganizationName) - new Date(b.fromOrganizationName),
            sortOrder: sortedInfo.columnKey === 'fromOrganizationName' && sortedInfo.order,
            width: '12%',
            // fixed: 'left',
            ellipsis: true,
         },
         {
            title: 'To', dataIndex: 'toOrganizationName', key: 'toOrganizationName',
            sorter: (a, b) => new Date(a.toOrganizationName) - new Date(b.toOrganizationName),
            sortOrder: sortedInfo.columnKey === 'toOrganizationName' && sortedInfo.order,
            width: '12%',
            // fixed: 'left',
            ellipsis: true,
         },
         {
            title: 'Status', dataIndex: 'transferStatus', key: 'transferStatus',
            filters: [{ text: 'Transferred', value: 'Transferred' }, { text: 'Transfer Rejected', value: 'Transfer Rejected' }, { text: 'Transfer Initiated', value: 'Transfer Initiated' }],
            filterMultiple: true,
            filteredValue: filteredInfo.transferStatus || null,
            onFilter: (value, record) => record.transferStatus.indexOf(value) === 0,
            width: '10%',
            // fixed: 'left',
            ellipsis: true,
            render: (transferStatus, record) => {
               return {
                  props: { style: { ...cellStyle } },
                  children: <>
                     {transferStatus === 'Transferred' && <Label empty size='tiny' color='green' circular></Label>}
                     {transferStatus === 'Transfer Rejected' && <Label empty size='tiny' color='red' circular></Label>}
                     {transferStatus === 'Transfer Initiated' && <Label empty size='tiny' color='orange' circular></Label>}
                     <span> {transferStatus}</span>
                  </>
               }
            }
         },
         {
            title: 'Actions', dataIndex: 'transferStatus', key: 'transferStatus',
            width: '10%',
            // fixed: 'left',
            ellipsis: true,
            render: (transferStatus, record) => {
               return {
                  props: { style: { ...cellStyle } },
                  children: <>
                     <span className='customLink' onClick={() => this.setState({ reviewModal: record })}>Review</span>
                  </>
               }
            }
         },

      ]


      return (
         <>
            <PageHeader />
            <DisplayMessage context={this} messageType={this.state.messageType} messageHeader={this.state.messageHeader} message={this.state.message} />
            <Segment basic className='py-0' loading={this.state.isFetching}>
               <div style={{ padding: '0px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <span style={{ display: 'contents', fontSize: '1.2em', fontWeight: '400' }}>{this.state.currentDataCount} {this.state.currentDataCount !== this.state.dataLength && `of ${this.state.dataLength}`} {t('results')} </span>
                  <div>
                     <span style={{ marginRight: '30px' }}>
                        {/* <span style={fLegend}><Icon color='blue' name='flask' /> Evaluation Required </span> */}
                     </span>

                     {/* {this.state.data.length > 0 && <JsonToCsv data={this.state.data} filename={filename} fields={fields} style={style} text={text} />} */}
                     {/* <Button icon='download' content='Download' className='mr-0 customBtn1Secondary mr-1' compact size='small' onClick={() => this.downloadAllDcns()} /> */}
                     <Button icon='redo' content={t('reload')} className='customBtn1Secondary' compact size='small' onClick={() => this.pageReload()} />
                     <Button icon='undo' content={t('resetFiltersAndSorters')} className='mr-0 customBtn1Secondary' compact size='small' onClick={this.clearAll} />
                  </div>
               </div>

               <Table
                  size="middle"
                  columns={columns}
                  rowKey={obj => obj.dcnId}
                  dataSource={this.state.data}
                  onChange={this.handleChange}
                  loading={{
                     indicator: <div><Loader active size='small' /></div>,
                     spinning: this.state.isFetching
                  }}
                  locale={{ emptyText: 'No Data' }}
                  className='border-1 bgWhite scrollableTable'
                  // scroll={{ x: 'calc(600px + 50%)' }}
                  scroll={{ x: 1080 }}
                  rowClassName={(record, index) => index % 2 === 0 ? 'bgWhite' : 'bgWhite'}
                  pagination={{ showTotal: (total, range) => this.state.currentDataCount !== total && this.setState({ currentDataCount: total }), defaultPageSize: 10, showSizeChanger: true, size: 'default', pageSizeOptions: ['10', '20', '40', '50', '100', '500',] }}
               />
            </Segment>

            {/* Review Modal */}
            {this.state.reviewModal && <Modal onClose={() => this.setState({ reviewModal: false })} centered={false} style={{ position: 'relative' }} size='fullscreen' open={this.state.reviewModal} dimmer='inverted'>
               <Modal.Content>
                  <Grid columns='equal'>
                     <Grid.Column className='py-0'>
                        <div className='textBig textBold mb-1'>Transfer Request</div>
                     </Grid.Column>
                     <Grid.Column textAlign='right'>
                        {(this.state.reviewModal.transferStatus === 'Transfer Initiated' && this.state.reviewModal.toBpid === utility.fetchUserBpid()) ? <Button.Group size='tiny'>
                           <Button negative onClick={() => this.setState({ rejectConfirmation: this.state.reviewModal.transferId })}>Reject</Button>
                           <Button.Or />
                           <Button positive onClick={() => this.setState({ acceptConfirmation: this.state.reviewModal.transferId })}>Accept</Button>
                        </Button.Group>
                           :
                           <>
                              {this.state.reviewModal.transferStatus === 'Transferred' && <Label empty size='tiny' color='green' circular></Label>}
                              {this.state.reviewModal.transferStatus === 'Transfer Rejected' && <Label empty size='tiny' color='red' circular></Label>}
                              {this.state.reviewModal.transferStatus === 'Transfer Initiated' && <Label empty size='tiny' color='orange' circular></Label>}
                              <span> {this.state.reviewModal.transferStatus}</span>
                           </>
                        }
                     </Grid.Column>
                  </Grid>
                  <div className='textNormal textBold mb-1 colPrimary'>Transfer Details</div>
                  <Grid columns={2}>
                     <Grid.Column className='py-0'>
                        <div className='contentLabel'>{t('from')}</div>
                        <div className='contentMedium'>{this.props.fetchOrganizationName(this.state.reviewModal.fromBpid)}</div>
                     </Grid.Column>
                     <Grid.Column className='py-0'>
                        <div className='contentLabel'>{t('to')}</div>
                        <div className='contentMedium'>{this.props.fetchOrganizationName(this.state.reviewModal.toBpid)}</div>
                     </Grid.Column>
                     <Grid.Column className='py-0'>
                        <div className='contentLabel'>{t('remarks')}</div>
                        <div className='contentMedium'>{this.state.reviewModal.remarks}</div>
                     </Grid.Column>
                     <Grid.Column className='py-0'>
                        <div className='contentLabel'>{t('transferStatus')}</div>
                        <div className='contentMedium'>{this.state.reviewModal.transferStatus}</div>
                     </Grid.Column>
                     <Grid.Column className='py-0'>
                        <div className='contentLabel'>{t('ppForename')}</div>
                        <div className='contentMedium'>{this.state.reviewModal.ppForename}</div>
                     </Grid.Column>
                     <Grid.Column className='py-0'>
                        <div className='contentLabel'>{t('ppSurname')}</div>
                        <div className='contentMedium'>{this.state.reviewModal.ppSurname}</div>
                     </Grid.Column>
                     <Grid.Column className='py-0'>
                        <div className='contentLabel'>{t('ppDob')}</div>
                        <div className='contentMedium'>{this.state.reviewModal.ppDob}</div>
                     </Grid.Column>
                     <Grid.Column className='py-0'>
                        <div className='contentLabel'>{t('ppAddressLine1')}</div>
                        <div className='contentMedium'>{this.state.reviewModal.ppAddressLine1}</div>
                     </Grid.Column>
                     <Grid.Column className='py-0'>
                        <div className='contentLabel'>{t('ppAddressLine2')}</div>
                        <div className='contentMedium'>{this.state.reviewModal.ppAddressLine2}</div>
                     </Grid.Column>
                     <Grid.Column className='py-0'>
                        <div className='contentLabel'>{t('ppAddressLine3')}</div>
                        <div className='contentMedium'>{this.state.reviewModal.ppAddressLine3}</div>
                     </Grid.Column>
                     <Grid.Column className='py-0'>
                        <div className='contentLabel'>{t('ppCity')}</div>
                        <div className='contentMedium'>{this.state.reviewModal.ppCity}</div>
                     </Grid.Column>
                     <Grid.Column className='py-0'>
                        <div className='contentLabel'>{t('ppPostCode')}</div>
                        <div className='contentMedium'>{this.state.reviewModal.ppPostCode}</div>
                     </Grid.Column>
                     <Grid.Column className='py-0'>
                        <div className='contentLabel'>{t('ppUprn')}</div>
                        <div className='contentMedium'>{this.state.reviewModal.ppUprn}</div>
                     </Grid.Column>
                  </Grid>
                  <Divider hidden />
                  <div className='textNormal textBold mb-1 colPrimary'>DCN Details</div>
                  <DcnsDetailPage dcnId={this.state.reviewModal.dcnIdNew || this.state.reviewModal.dcnId} fetchOrganizationList={this.props.fetchOrganizationList} fetchOrganizationName={this.props.fetchOrganizationName} />
               </Modal.Content>
               <Modal.Actions>
                  <Button icon='cancel' className='customBtn1Secondary' onClick={() => this.setState({ reviewModal: false })}>Close</Button>
               </Modal.Actions>
            </Modal>}

            {/* Accept Modal */}
            <Modal open={this.state.acceptConfirmation} dimmer='inverted'>
               <Modal.Content>
                  <div className='textBig colPrimary textBold mb-1'></div>
                  <div className='textBig textBold '>Are you sure you want to accept ?</div>
               </Modal.Content>
               <Modal.Actions>
                  <Button icon='cancel' className='customBtn1Secondary' onClick={() => this.setState({ acceptConfirmation: false })}>Cancel</Button>
                  <Button className='customBtn1Primary' onClick={() => this.acceptTransfer()} loading={this.state.isAccepting}>Yes</Button>
               </Modal.Actions>
            </Modal>
            {/* Reject Modal */}
            <Modal open={this.state.rejectConfirmation} dimmer='inverted'>
               <Modal.Content>
                  <div className='textBig colPrimary textBold mb-1'></div>
                  <div className='textBig textBold '>Are you sure you want to reject ?</div>
               </Modal.Content>
               <Modal.Actions>
                  <Button icon='cancel' className='customBtn1Secondary' onClick={() => this.setState({ rejectConfirmation: false })}>Cancel</Button>
                  <Button className='customBtn1Primary' onClick={() => this.rejectTransfer()} loading={this.state.isRejecting}>Yes</Button>
               </Modal.Actions>
            </Modal>
         </>
      );

   }

}

export default withTranslation()(withRouter(TransferRequests))
