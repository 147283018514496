
import React from 'react'
import { withRouter, Link } from 'react-router-dom';
import utility from '../../commons/utility.js';
import ajax from '../../commons/agent.js';
import { Button, Input, Icon, Grid, Modal, Image, Tab, Form, TextArea, Select, Segment, Header, Label } from 'semantic-ui-react';
import AdvSearch from './AdvSearch.jsx';
import moment from 'moment'
// import PdfPreview from '../../commons/PdfPreview.jsx'
import PageHeader from '../PageHeader.jsx';
import DisplayMessage from '../../commons/displayMessage.jsx';
import CreateDcn from './createDcn/CreateDcn.jsx';
import DatePicker from "react-datepicker";
import DcnHistoryCard from '../cards/DcnHistoryCard.jsx';
import { withTranslation } from "react-i18next";
import AddressLookUp from './AddressLookUp.jsx';
import AddressLookUpMap from '../../commons/geomap/AddressLookUpMap.jsx';
import PdfPreview from '../../commons/fileupload/PdfPreview.jsx';
import BreedImage from '../../commons/fileupload/BreedImage.jsx';

class DcnsDetailPage extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         messageType: '', messageHeader: '', message: [],

         isFetching: false,
         dischargeDcnModal: false,
         data: null,
         variationsList: [],
         error: {}

      };
   }

   componentDidMount() {
      this.pageReload();
   }
   // componentWillReceiveProps(nextProps) {
   //     if (nextProps.location !== this.props.location) {
   //         this.pageReload();
   //     }
   // }
   componentDidUpdate(prevProps, prevState) {
      if (prevProps.location !== this.props.location) {
         this.pageReload();
      }
   }

   pageReload() {
      var self = this
      self.setState({ isFetching: true })
      ajax.DCNService.findDcnDetailsByDcnId(this.props.dcnId || this.props.match.params.dcnId).end((err, response) => {
         self.setState({ isFetching: false })
         if (!err && response) {
            self.setState({ data: response.body }, () => this.fetchVariations())
         }
         else {
            utility.loadDisplayMessage(self, "error", "Something went wrong.", [])
         }
      });

      const orgList = this.props.fetchOrganizationList()
      const myBpid = utility.fetchUserBpid()
      this.setState({
         orgList: orgList,
         transferToBpidOptions: orgList.filter(item => (item.organizationBpid != myBpid && item.organizationCategory === 'Local Authority')).map(item => ({ key: item.organizationBpid, text: `${item.organizationName}`, value: item.organizationBpid }))
      })

   }

   fetchVariations = () => {
      this.setState({ isFetchingVariations: true })
      this.state.data.dcnId && ajax.DCNService.fetchVariations(this.state.data.dcnId).end((err, response) => {
         this.setState({ isFetchingVariations: false })
         if (!err && response) {
            this.setState({ variationsList: response.body })
         }
         else {
            utility.loadDisplayMessage(self, "error", "Something went wrong.", ['Please contact system administrator.'])
         }
      });
   }

   initializeTranferForm = (data) => {
      this.addressLookUpReset()

      this.setState({
         transferDcnId: data.dcnId,
         transferDcnReferenceNo: data.dcnReferenceNo,
         transferToBpid: null,
         transferRemarks: '',
         transferPpForename: data.ppForename,
         transferPpSurname: data.ppSurname,
         transferPpDob: new Date(data.ppDob),
         transferPpAddressLine1: '',
         transferPpAddressLine2: '',
         transferPpAddressLine3: '',
         transferPpCity: '',
         transferPpPostCode: '',
         transferPpUprn: '',
         transferXCoordinate: '',
         transferYCoordinate: '',

         selectedMapObj: {},
         hasLookedUpAddress: false,
      })
   }

   handleTextChange = (e) => {
      this.setState({ [e.target.name]: e.target.value })
   }
   handleRadioChange = (name, value) => {
      this.setState({ [name]: value })
   }
   handleDropdownChange = (name, value) => {
      this.setState({ [name]: value })
   }

   dischargeDcn = () => {
      let self = this
      let Vo = { dcnId: self.props.match.params.dcnId, effectiveDate: moment.utc(self.state.dischargeDate).format("YYYY-MM-DD HH:mm") }
      if (Vo.effectiveDate) {
         self.setState({ isDischarging: true })
         ajax.DCNService.dischargeDcn(Vo).end((err, response) => {
            self.setState({ dischargeDcnModal: false, isDischarging: false })
            if (!err && response) {
               self.props.history.push(`/dashboard/dogControlNotices/dcnsdetailpage/${response.body.dcnId}`);
            }
            else { utility.loadDisplayMessage(self, "error", "Something went wrong.", ['Please contact system administrator.']) }
         });
      }
      else { utility.loadDisplayMessage(self, "error", "Error", ['Fill the mandatory fields.']) }
   }
   suspendDcn = () => {
      let self = this
      let Vo = { dcnId: self.props.match.params.dcnId, effectiveDate: moment.utc(self.state.suspensionDate).format("YYYY-MM-DD HH:mm") }
      if (Vo.effectiveDate) {
         self.setState({ isSuspending: true })
         ajax.DCNService.suspendDcn(Vo).end((err, response) => {
            self.setState({ suspendDcnModal: false, isSuspending: false })
            if (!err && response) {
               self.props.history.push(`/dashboard/dogControlNotices/dcnsdetailpage/${response.body.dcnId}`);
            }
            else { utility.loadDisplayMessage(self, "error", "Something went wrong.", ['Please contact system administrator.']) }
         });
      }
      else { utility.loadDisplayMessage(self, "error", "Error", ['Fill the mandatory fields.']) }
   }
   reactivateDcn = () => {
      let self = this
      let Vo = { dcnId: self.props.match.params.dcnId, effectiveDate: moment.utc(self.state.reactivationDate).format("YYYY-MM-DD HH:mm") }
      if (Vo.effectiveDate) {
         self.setState({ isreactivateing: true })
         ajax.DCNService.reactivateDcn(Vo).end((err, response) => {
            self.setState({ reactivateDcnModal: false, isReactivating: false })
            if (!err && response) {
               self.props.history.push(`/dashboard/dogControlNotices/dcnsdetailpage/${response.body.dcnId}`);
            }
            else { utility.loadDisplayMessage(self, "error", "Something went wrong.", ['Please contact system administrator.']) }
         });
      }
      else { utility.loadDisplayMessage(self, "error", "Error", ['Fill the mandatory fields.']) }
   }
   createVariation = () => {
      let self = this
      self.setState({ isUpdating: true })
      ajax.DCNService.updateDcn(self.props.match.params.dcnId).end((err, response) => {
         self.setState({ createVariationModal: false, isUpdating: false })
         if (!err && response) {
            self.props.history.push(`/dashboard/dogControlNotices/dcnsdetailpage/${response.body.dcnId}`);
         }
         else { utility.loadDisplayMessage(self, "error", "Something went wrong.", ['Please contact system administrator.']) }
      });

   }
   // Transfer Validation
   validateTransferToBpid = () => {
      let error = ''
      const value = this.state.transferToBpid
      if (!value) error = "Required"

      this.setState(prevState => ({ error: ({ ...prevState.error, transferToBpid: error }) }))
      return error
   }
   validateTransferRemarks = () => {
      let error = ''
      const value = this.state.transferRemarks
      if (value && value.length > 2000) error = "Length should be less than 2000 characters"

      this.setState(prevState => ({ error: ({ ...prevState.error, transferRemarks: error }) }))
      return error
   }
   validateTransferPersonForename = () => {
      let error = ''
      const value = this.state.transferPpForename
      if (!value) error = 'Required'
      else if (value.length > 50) error = "Length should be less than 50 characters"

      this.setState(prevState => ({ error: ({ ...prevState.error, transferPpForename: error }) }))
      return error
   }

   validateTransferPersonSurname = () => {
      let error = ''
      const value = this.state.transferPpSurname
      if (!value) error = 'Required'
      else if (value.length > 50) error = "Length should be less than 50 characters"

      this.setState(prevState => ({ error: ({ ...prevState.error, transferPpSurname: error }) }))
      return error
   }

   validateTransferPersonDob = () => {
      let error = ''
      const value = this.state.transferPpDob
      // if (!value) error = 'Date is required in DD/MM/YYYY format'

      this.setState(prevState => ({ error: ({ ...prevState.error, transferPpDob: error }) }))
      return error
   }

   validateTransferPersonAddressLine1 = () => {
      let error = ''
      const value = this.state.transferPpAddressLine1
      if (!value) error = 'Required'
      else if (value.length > 2000) error = "Length should be less than 2000 characters"

      this.setState(prevState => ({ error: ({ ...prevState.error, transferPpAddressLine1: error }) }))
      return error
   }

   validateTransferPersonAddressLine2 = () => {
      let error = ''
      const value = this.state.transferPpAddressLine2
      if (value && value.length > 2000) error = "Length should be less than 2000 characters"

      this.setState(prevState => ({ error: ({ ...prevState.error, transferPpAddressLine2: error }) }))
      return error
   }
   validateTransferPersonAddressLine3 = () => {
      let error = ''
      const value = this.state.transferPpAddressLine3
      if (value && value.length > 2000) error = "Length should be less than 2000 characters"

      this.setState(prevState => ({ error: ({ ...prevState.error, transferPpAddressLine3: error }) }))
      return error
   }

   validateTransferPersonCity = () => {
      let error = ''
      const value = this.state.transferPpCity
      if (!value) error = 'Required'
      else if (value.length > 2000) error = "Length should be less than 2000 characters"

      this.setState(prevState => ({ error: ({ ...prevState.error, transferPpCity: error }) }))
      return error
   }

   validateTransferPersonPostCode = () => {
      let error = ''
      const value = this.state.transferPpPostCode
      if (!value) error = 'Required'
      else if (value.length > 50) error = "Length should be less than 50 characters"

      this.setState(prevState => ({ error: ({ ...prevState.error, transferPpPostCode: error }) }))
      return error
   }

   validateTransferPersonUprn = () => {
      let error = ''
      const value = this.state.transferPpUprn
      if (value && value.length > 50) error = "Length should be less than 50 characters"


      this.setState(prevState => ({ error: ({ ...prevState.error, transferPpUprn: error }) }))
      return error
   }
   validateTransferAll = (Vo) => {
      this.validateTransferRemarks()
      this.validateTransferToBpid()
      this.validateTransferPersonForename()
      this.validateTransferPersonSurname()
      this.validateTransferPersonDob()
      this.validateTransferPersonAddressLine1()
      this.validateTransferPersonAddressLine2()
      this.validateTransferPersonAddressLine3()
      this.validateTransferPersonCity()
      this.validateTransferPersonPostCode()
      this.validateTransferPersonUprn()

      return (
         this.validateTransferToBpid() ||
         this.validateTransferRemarks() ||
         this.validateTransferPersonForename() ||
         this.validateTransferPersonSurname() ||
         this.validateTransferPersonDob() ||
         this.validateTransferPersonAddressLine1() ||
         this.validateTransferPersonAddressLine2() ||
         this.validateTransferPersonAddressLine3() ||
         this.validateTransferPersonCity() ||
         this.validateTransferPersonPostCode() ||
         this.validateTransferPersonUprn()
      )
   }
   transferDcn = () => {
      let self = this
      const Vo = {
         dcnId: this.state.transferDcnId,
         dcnReferenceNo: this.state.transferDcnReferenceNo,
         toBpid: this.state.transferToBpid,
         remarks: this.state.transferRemarks,
         ppForename: this.state.transferPpForename,
         ppSurname: this.state.transferPpSurname,
         ppDob: this.state.transferPpDob ? moment(this.state.transferPpDob).format("YYYY-MM-DD HH:mm") : null,
         ppAddressLine1: this.state.transferPpAddressLine1,
         ppAddressLine2: this.state.transferPpAddressLine2,
         ppAddressLine3: this.state.transferPpAddressLine3,
         ppCity: this.state.transferPpCity,
         ppPostCode: this.state.transferPpPostCode,
         ppUprn: this.state.transferPpUprn,
         xCoordinate: this.state.transferXCoordinate,
         yCoordinate: this.state.transferYCoordinate,
      }
      if (this.validateTransferAll()) {
         utility.loadDisplayMessage(this, "error", "Invalid Input", ['Fill the required form fields with valid data.'])
      }
      else {
         self.setState({ isTransfering: true })
         ajax.DCNService.initiateTransfer(Vo).end((err, response) => {
            self.setState({ transferDcnModal: false, isTransfering: false })
            if (!err && response) {
               self.props.history.push(`/dashboard/transferRequests`);
            }
            else { utility.loadDisplayMessage(self, "error", "Something went wrong.", ['Please contact system administrator.']) }
         });
      }
   }
   downloadFile = (dcnId, filename) => {
      let self = this
      if (dcnId && filename) {
         var promise = new Promise((resolve, reject) => {
            self.setState({ isDownloadingFile: true })
            ajax.DCNService.downloadFile(dcnId, filename).end(async (err, response) => {
               self.setState({ isDownloadingFile: false })
               let errJson = utility.handleErrors(err);
               if (!err && response) {
                  let msg = [];
                  resolve(response);
               } else {
                  utility.loadDisplayMessage(self, "error", "Failure.", errJson.errMessage);
               }
            })
         }).then((resolve) => {
            var headers = resolve.headers;
            var blob = new Blob([resolve.xhr.response], { type: headers['content-type'] });    //{type:headers['content-type']}
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            link.remove();
            link.addEventListener("focus", w => { window.URL.revokeObjectURL(blob) });

         }).then((reject) => {
            //utility.loadDisplayMessage(self, "error", "Failure.", errJson.errMessage);
         }).finally(() => {
         })
      }
   }

   addressLookUp = (e) => {
      e.preventDefault()
      let { lookupAddressText, lookupAddressUprn } = this.state
      let laCode = this.state.orgList.filter(org => org.organizationBpid === this.state.transferToBpid)[0].laCode
      let query = {
         "dataset": null,
         "attribute": []
      }
      if (lookupAddressText) query.attribute = [...query.attribute, { name: 'ADDRESS_STRING', matchtype: 'contains', value: [lookupAddressText] }]
      if (lookupAddressUprn) query.attribute = [...query.attribute, { name: 'UPRN', value: [lookupAddressUprn] }]
      if (laCode) query.attribute = [...query.attribute, { name: 'LA_CODE', matchtype: 'equal to', value: [laCode] }]

      let Vo = { query }
      if (!lookupAddressText && !lookupAddressUprn) {
         utility.loadDisplayMessage(this, "warning", "Empty Address", ['Fill something in the address fields to look up.'])
      }
      else {
         this.setState({ isLookingUpAddress: true, })
         ajax.DCNService.addressLookUp(Vo).end((err, res) => {
            this.setState({ isLookingUpAddress: false, hasLookedUpAddress: true })
            if (!err && res) {
               let dataArr = res.body.SearchResponseMessage.NGSearchResponseData.Result.any.map(item => ({ ...item, x: item.X_COORD, y: item.Y_COORD, status: 'Active' }))
               let selectedMapObj = this.state.selectedMapObj && dataArr.filter(item => item.UPRN == this.state.selectedMapObj.UPRN)
               this.setState({
                  addressLookUpList: [...dataArr],
                  // selectedMapObj: selectedMapObj && selectedMapObj.length > 0 ? selectedMapObj[0] : {},
               })
            }
            else {
               utility.loadDisplayMessage(this, "error", "Error", ['Something Went Wrong'])
            }
         })
      }

      // let res = {
      //    "SearchResponseMessage": {
      //       "NGSearchResponseData": {
      //          "Header": {
      //             "ResultCount": 22,
      //             "ReturnCount": 22,
      //             "ErrorCode": 0,
      //             "ErrorMessage": "Success"
      //          },
      //          "Result": {
      //             "any": [
      //                {
      //                   "UPRN": 35000001,
      //                   "Y_COORD": 693618,
      //                   "TOWN": "ALLOA",
      //                   "ADD_LINE_1": "1",
      //                   "X_COORD": 287561,
      //                   "ADDRESS_STRING": "1 ACADEMY STREET ALLOA FK10 2EA",
      //                   "ADD_LINE_2": "",
      //                   "LA_CODE": 9056,
      //                   "ADD_LINE_3": "ACADEMY STREET",
      //                   "POST_CODE": "FK10 2EA",
      //                   "START_DATE": 733618800000,
      //                   "USRN": "8500253"
      //                },
      //                {
      //                   "UPRN": 35000002,
      //                   "Y_COORD": 693614,
      //                   "TOWN": "ALLOA",
      //                   "ADD_LINE_1": "2",
      //                   "X_COORD": 287521,
      //                   "ADDRESS_STRING": "2 ACADEMY STREET ALLOA FK10 2EA",
      //                   "ADD_LINE_2": "",
      //                   "LA_CODE": 9056,
      //                   "ADD_LINE_3": "ACADEMY STREET",
      //                   "POST_CODE": "FK10 2EA",
      //                   "START_DATE": 481158000000,
      //                   "USRN": "8500253"
      //                },
      //                {
      //                   "UPRN": 35000004,
      //                   "Y_COORD": 693610,
      //                   "TOWN": "ALLOA",
      //                   "ADD_LINE_1": "4",
      //                   "X_COORD": 287517,
      //                   "ADDRESS_STRING": "4 ACADEMY STREET ALLOA FK10 2EA",
      //                   "ADD_LINE_2": "",
      //                   "LA_CODE": 9056,
      //                   "ADD_LINE_3": "ACADEMY STREET",
      //                   "POST_CODE": "FK10 2EA",
      //                   "START_DATE": 481158000000,
      //                   "USRN": "8500253"
      //                },
      //                {
      //                   "UPRN": 35000005,
      //                   "Y_COORD": 693564,
      //                   "TOWN": "ALLOA",
      //                   "ADD_LINE_1": "5",
      //                   "X_COORD": 287527,
      //                   "ADDRESS_STRING": "5 ACADEMY STREET ALLOA FK10 2EA",
      //                   "ADD_LINE_2": "",
      //                   "LA_CODE": 9056,
      //                   "ADD_LINE_3": "ACADEMY STREET",
      //                   "POST_CODE": "FK10 2EA",
      //                   "START_DATE": 481158000000,
      //                   "USRN": "8500253"
      //                },
      //                {
      //                   "UPRN": 35000006,
      //                   "Y_COORD": 693604,
      //                   "TOWN": "ALLOA",
      //                   "ADD_LINE_1": "6",
      //                   "X_COORD": 287511,
      //                   "ADDRESS_STRING": "6 ACADEMY STREET ALLOA FK10 2EA",
      //                   "ADD_LINE_2": "",
      //                   "LA_CODE": 9056,
      //                   "ADD_LINE_3": "ACADEMY STREET",
      //                   "POST_CODE": "FK10 2EA",
      //                   "START_DATE": 481158000000,
      //                   "USRN": "8500253"
      //                },
      //                {
      //                   "UPRN": 35000007,
      //                   "Y_COORD": 693562,
      //                   "TOWN": "ALLOA",
      //                   "ADD_LINE_1": "7",
      //                   "X_COORD": 287525,
      //                   "ADDRESS_STRING": "7 ACADEMY STREET ALLOA FK10 2EA",
      //                   "ADD_LINE_2": "",
      //                   "LA_CODE": 9056,
      //                   "ADD_LINE_3": "ACADEMY STREET",
      //                   "POST_CODE": "FK10 2EA",
      //                   "START_DATE": 481158000000,
      //                   "USRN": "8500253"
      //                },
      //                {
      //                   "UPRN": 35000009,
      //                   "Y_COORD": 693552,
      //                   "TOWN": "ALLOA",
      //                   "ADD_LINE_1": "9",
      //                   "X_COORD": 287519,
      //                   "ADDRESS_STRING": "9 ACADEMY STREET ALLOA FK10 2EA",
      //                   "ADD_LINE_2": "",
      //                   "LA_CODE": 9056,
      //                   "ADD_LINE_3": "ACADEMY STREET",
      //                   "POST_CODE": "FK10 2EA",
      //                   "START_DATE": 481158000000,
      //                   "USRN": "8500253"
      //                }

      //             ]
      //          }
      //       }
      //    }
      // }

      // let d = res.SearchResponseMessage.NGSearchResponseData.Result.any.map(item => ({ ...item, x: item.X_COORD, y: item.Y_COORD, status: 'Active' }))
      // this.setState({
      //    addressLookUpList: d,
      //    selectedMapObj: this.state.selectedMapObj && d.filter(item => item.UPRN == this.state.selectedMapObj.UPRN),
      //    hasLookedUpAddress: true
      // })
   }
   addressLookUpReset = () => {
      this.setState({
         addressLookUpList: [],
         selectedMapObj: null,
         lookupAddressText: '',
         lookupAddressUprn: '',

         transferPpAddressLine1: '',
         transferPpAddressLine2: '',
         transferPpAddressLine3: '',
         transferPpCity: '',
         transferPpPostCode: '',
         transferPpUprn: '',
         transferXCoordinate: '',
         transferYCoordinate: '',
         selectedMapObj: '',
      })
   }

   handleAddressSelect = (data) => {
      this.setState({

         transferPpAddressLine1: data.ADD_LINE_1 || '',
         transferPpAddressLine2: data.ADD_LINE_2 || data.ADD_LINE_3 || '',
         transferPpAddressLine3: data.ADD_LINE_2 ? data.ADD_LINE_3 || '' : '',
         transferPpCity: data.TOWN || '',
         transferPpPostCode: data.POST_CODE || '',
         transferPpUprn: data.UPRN || '',
         transferXCoordinate: data.X_COORD || '',
         transferYCoordinate: data.Y_COORD || '',
         selectedMapObj: data,
      })
   }


   startVariation = () => {
      let self = this
      self.setState({ startingAction: true, })
      ajax.DCNService.alreadyInitiatedTransfer(self.state.data.dcnId).end((err, res) => {
         self.setState({ startingAction: false })
         if (!err && res) {
            if (res.body == true) {
               utility.loadDisplayMessage(self, "warning", "Not Allowed", ['Transfer has been initiated for this DCN.'])
            }
            else {
               self.setState({ createVariationModal: true, variationDate: new Date(new Date().setHours(0, 0, 0, 0)) })
            }
         }
         else {
            utility.loadDisplayMessage(self, "error", "Error", ['Something Went Wrong'])
         }
      })
   }
   startDischarge = () => {
      let self = this
      self.setState({ startingAction: true, })
      ajax.DCNService.alreadyInitiatedTransfer(self.state.data.dcnId).end((err, res) => {
         self.setState({ startingAction: false })
         if (!err && res) {
            if (res.body == true) {
               utility.loadDisplayMessage(self, "warning", "Not Allowed", ['Transfer has been initiated for this DCN.'])
            }
            else {
               self.setState({ dischargeDcnModal: true, dischargeDate: new Date(new Date().setHours(0, 0, 0, 0)) })
            }
         }
         else {
            utility.loadDisplayMessage(self, "error", "Error", ['Something Went Wrong'])
         }
      })
   }
   startSuspend = () => {
      let self = this
      self.setState({ startingAction: true, })
      ajax.DCNService.alreadyInitiatedTransfer(self.state.data.dcnId).end((err, res) => {
         self.setState({ startingAction: false })
         if (!err && res) {
            if (res.body == true) {
               utility.loadDisplayMessage(self, "warning", "Not Allowed", ['Transfer has been initiated for this DCN.'])
            }
            else {
               self.setState({ suspendDcnModal: true, suspensionDate: new Date(new Date().setHours(0, 0, 0, 0)) })
            }
         }
         else {
            utility.loadDisplayMessage(self, "error", "Error", ['Something Went Wrong'])
         }
      })
   }
   startTransfer = () => {
      let self = this
      self.setState({ startingAction: true, })
      ajax.DCNService.alreadyInitiatedTransfer(self.state.data.dcnId).end((err, res) => {
         self.setState({ startingAction: false })
         if (!err && res) {
            if (res.body == true) {
               utility.loadDisplayMessage(self, "warning", "Not Allowed", ['Transfer has been initiated for this DCN.'])
            }
            else {
               self.setState({ transferDcnModal: true, dischargeDate: new Date(new Date().setHours(0, 0, 0, 0)) }, () => self.initializeTranferForm(self.state.data))
            }
         }
         else {
            utility.loadDisplayMessage(self, "error", "Error", ['Something Went Wrong'])
         }
      })
   }
   render() {
      const { t } = this.props
      const { data } = this.state
      const formMode = data ? utility.resolveDcnStatus(data.parentDcnId, data.dcnStatus) : null
      const viewMode = this.props.dcnId ? true : false

      if (formMode === 'Draft' || formMode === 'Variation') {
         return (
            <>
               <CreateDcn formData={this.state.data} fetchOrganizationList={this.props.fetchOrganizationList} fetchOrganization={this.props.fetchOrganization} fetchOrganizationCategory={this.props.fetchOrganizationCategory} />
            </>
         )
      }

      return (
         <>
            {!viewMode && <PageHeader customPath={`/dashboard/dogControlNotices/DCN`} />}
            <DisplayMessage context={this} messageType={this.state.messageType} messageHeader={this.state.messageHeader} message={this.state.message} />
            <Segment basic className='p-0' loading={this.state.isFetching}>
               {data &&
                  <div className='px-0'>
                     {!viewMode && <AdvSearch />}

                     <Grid columns='equal' className="mx-0 px-0">
                        {/* r1 c1 */}
                        <Grid.Column mobile={16} tablet={16} computer={(!viewMode && data.bpid === utility.fetchUserBpid()) ? 11 : 16} className="m-1 p-2 bgWhite border-1 bgShadow" >
                           <Grid columns='equal'>
                              <Grid.Column>
                                 <div className='textBig textBold'>DCN Ref No. {data.dcnReferenceNo}</div>
                              </Grid.Column>
                              <Grid.Column className='flex flexJustifyEnd'>
                                 {data.dcnStatus === 'Active' && <Label className='textThin' size='large' color='green'>ACTIVE</Label>}
                                 {data.dcnStatus === 'Discharged' && <Label className='textThin' size='large' color='grey'>DISCHARGED</Label>}
                                 {data.dcnStatus === 'Draft' && <Label className='textThin' size='large' color='blue'>DRAFT</Label>}
                                 {data.dcnStatus === 'Inactive' && <Label className='textThin' size='large' color='grey'>INACTIVE</Label>}
                                 {data.dcnStatus === 'Suspended' && <Label className='textThin' size='large' color='red'>SUSPENDED</Label>}
                                 {data.dcnStatus === 'Expired' && <Label className='textThin' size='large' color='grey'>EXPIRED</Label>}
                                 {data.dcnStatus === 'Auto updated' && <Label className='textThin' size='large' color='teal'>AUTO UPDATED</Label>}
                              </Grid.Column>
                           </Grid>
                           <Grid columns='equal'>
                              <Grid.Column mobile={16} tablet={5} computer={5} className='flex flexDirectionColumn flexAlignCenter'>
                                 <BreedImage imageName={`${data.dogImgLocation}`} style={{ maxHeight: '320px' }} />
                                 {/* <Image src={this.state.breedImgDownloadedLink} alt="demoImg" style={{ maxHeight: '320px' }} /> */}
                                 {/* <Image src={`/dogbreeds/${data.dogImgLocation}`} alt="demoImg" style={{ maxHeight: '320px' }} /> */}
                                 <div className='textNormal text-center'><br />{data.dogBreed}</div>
                              </Grid.Column>
                              <Grid.Column>
                                 <Grid columns='equal' className='m-0'>
                                    <Grid.Column className='py-0'>
                                       <p className='contentLabel'>Dog's name</p>
                                       <p className='contentBig'>{data.dogName}</p>

                                       <p className='contentLabel'>Microchip No.</p>
                                       <p className='contentBig'>{data.dogMicrochipCode}</p>

                                       <p className='contentLabel'>Breed</p>
                                       <p className='contentBig'>{data.dogBreed}</p>

                                       <p className='contentLabel'>Sex</p>
                                       <p className='contentBig'>{data.dogSex}</p>

                                       <p className='contentLabel'>Description of dog / Identification Mark</p>
                                       <p className='contentBig'>{data.dogIdentificationMark}</p>
                                    </Grid.Column>
                                    <Grid.Column className='p-0'>
                                       <p className='contentLabel'>Proper Person</p>
                                       <p className='textBig textBold mb-5px'>{`${data.ppForename} ${data.ppSurname}`}</p>
                                       {data.ppAddressLine1 && <p className='contentBig mb-5px'>{`${data.ppAddressLine1}`}</p>}
                                       {data.ppAddressLine2 && <p className='contentBig mb-5px'>{`${data.ppAddressLine2}`}</p>}
                                       {data.ppAddressLine3 && <p className='contentBig mb-5px'>{`${data.ppAddressLine3}`}</p>}
                                       <p className='contentBig'>{`${data.ppCity} ${data.ppPostCode}`}</p>

                                       <p className='textNormal customColGrey'>
                                          {/*Served on {moment.utc(data.effectiveDate || data.dcnEffectiveDate).local().format("DD-MM-YYYY")}<br />*/}
                                          Served on {moment.utc(data.dcnEffectiveDate).local().format("DD-MM-YYYY")}<br />
                                          Updated on {data.updatedAt && utility.formatDate(data.updatedAt)}
                                       </p>

                                       <p className='contentBig customColGrey'>
                                          By {`${data.issuerForename} ${data.issuerSurname}`}<br />
                                          <Link to={`/dashboard/organisations/${data.bpid}`} className='colPrimary'>{this.props.fetchOrganizationName(data.bpid)}</Link>
                                       </p>

                                    </Grid.Column>

                                 </Grid>
                              </Grid.Column>
                           </Grid>
                        </Grid.Column>

                        {/* r1 c2   right side based on status */}
                        {(!viewMode && data.bpid === utility.fetchUserBpid()) &&
                           <>
                              <Grid.Column stretched className='m-1' >
                                 {data.dcnStatus === 'Active' &&
                                    <Grid>
                                       <Grid.Column className="p-2 bgWhite border-1 bgShadow">
                                          <div className='textBig textBold mb-1'>Change in Circumstances</div>
                                          <p className='textNormal'>Any change of address, proper person, restrictions or circumstance?</p>

                                          <Grid columns='equal'>
                                             <Grid.Column stretched mobile={8} tablet={4} computer={16}>
                                                <Button className='customBtn1Secondary py-1' fluid size='large' onClick={() => this.startVariation()} loading={this.state.startingAction}>Create a Variation</Button>
                                                {/* <Button className='customBtn1Secondary py-1' fluid size='large' onClick={() => this.setState({ createVariationModal: true, variationDate: new Date(new Date().setHours(0, 0, 0, 0)) })}>Create a Variation</Button> */}
                                             </Grid.Column>

                                             <Grid.Column stretched mobile={8} tablet={4} computer={16}>
                                                <Button className='customBtn1Secondary py-1' fluid size='large' onClick={() => this.startDischarge()} loading={this.state.startingAction}>Discharge DCN</Button>
                                                {/* <Button className='customBtn1Secondary py-1' fluid size='large' onClick={() => this.setState({ dischargeDcnModal: true, dischargeDate: new Date(new Date().setHours(0, 0, 0, 0)) })}>Discharge DCN</Button> */}
                                             </Grid.Column>

                                             <Grid.Column stretched mobile={8} tablet={4} computer={16}>
                                                <Button className='customBtn1Secondary py-1' fluid size='large' onClick={() => this.startSuspend()} loading={this.state.startingAction}>Suspend DCN</Button>
                                                {/* <Button className='customBtn1Secondary py-1' fluid size='large' onClick={() => this.setState({ suspendDcnModal: true, suspensionDate: new Date(new Date().setHours(0, 0, 0, 0)) })}>Suspend DCN</Button> */}
                                             </Grid.Column>

                                             <Grid.Column stretched mobile={8} tablet={4} computer={16}>
                                                <Button className='customBtn1Secondary py-1' fluid size='large' onClick={() => this.startTransfer()} loading={this.state.startingAction}>Transfer DCN</Button>
                                                {/* <Button className='customBtn1Secondary py-1' fluid size='large' onClick={() => this.setState({ transferDcnModal: true, dischargeDate: new Date(new Date().setHours(0, 0, 0, 0)) }, () => this.initializeTranferForm(data))}>Transfer DCN</Button> */}
                                             </Grid.Column>
                                          </Grid>
                                       </Grid.Column>
                                    </Grid>
                                 }
                                 {(data.dcnStatus === 'Discharged' || data.dcnStatus === 'Suspended') &&
                                    <Grid>
                                       <Grid.Column className="p-2 bgWhite border-1 bgShadow">
                                          <div className='textBig textBold mb-1'>This DCN is {data.dcnStatus}</div>

                                          <Grid>
                                             <Grid.Row columns='1'>
                                                <Grid.Column>
                                                   <p className='textMedium customColGrey'>
                                                      {data.dcnStatus} on {utility.formatDate(data.effectiveDate)}
                                                   </p>

                                                   <p className='textMedium customColGrey'>
                                                      By
                                                      <a className='colPrimary'> {data.createdBy} </a><br />
                                                      {this.props.fetchOrganizationName(data.bpid)}
                                                   </p>
                                                </Grid.Column>
                                                {
                                                   data.dcnStatus === 'Suspended' &&
                                                   <>
                                                      <Grid.Column className='mt-2'>
                                                         <Button className='customBtn1Secondary py-1' fluid size='large' onClick={() => this.setState({ reactivateDcnModal: true, reactivationDate: new Date(new Date().setHours(0, 0, 0, 0)) })}>Reactivate DCN</Button>
                                                      </Grid.Column>
                                                      <Grid.Column className='mt-2'>
                                                         <Button className='customBtn1Secondary py-1' fluid size='large' onClick={() => this.setState({ dischargeDcnModal: true, dischargeDate: new Date(new Date().setHours(0, 0, 0, 0)) })}>Discharge DCN</Button>
                                                      </Grid.Column>
                                                   </>
                                                }
                                             </Grid.Row>
                                          </Grid>
                                       </Grid.Column>
                                    </Grid>
                                 }
                              </Grid.Column>
                           </>
                        }
                     </Grid>

                     {this.state.variationsList.length > 1 &&
                        <Grid columns={1} className="mx-0 py-0" as={Segment} basic loading={this.state.isFetchingVariations}>
                           <Grid.Column className='mx-1 px-2 bgWhite border-1 bgShadow' >
                              <div className='textBig textBold mb-1'>History ({this.state.variationsList.length})</div>
                              {this.state.variationsList.map((item, key) => {
                                 return (
                                    <DcnHistoryCard fetchOrganizationName={this.props.fetchOrganizationName} dcnDetailPgContext={this} key={item.dcnId} data={item} isLast={this.state.variationsList.length - 1 === key} />
                                 )
                              })}
                           </Grid.Column>
                           <Grid.Column>
                              {/* PDF Preview */}
                              {this.state.pdfPreviewSelected &&
                                 <Segment>
                                    <Header as='h3' className='colPrimary' dividing>
                                       {this.state.pdfPreviewSelected.fileName}
                                    </Header>
                                    <Icon size='large' style={{ position: 'absolute', right: '6px', top: '12px', zIndex: '1' }} link name='close' onClick={() => this.setState({ pdfPreviewSelected: false })} />
                                    {/* <PdfPreview url={window.location.protocol + '//' + window.location.host + "/scannedpdf/" + this.state.pdfPreviewSelected.bpid + '/' + this.state.pdfPreviewSelected.fileName} /> */}
                                    <PdfPreview
                                       refId={data.dcnId}
                                       fileName={this.state.pdfPreviewSelected.fileName}
                                    />
                                 </Segment>
                              }
                           </Grid.Column>
                        </Grid>
                     }


                     <Grid columns='equal' className="mx-0 py-0 mb-1">
                        <Grid.Column stretched className='m-1' >
                           <Grid>
                              <Grid.Column className="p-2 bgWhite border-1 bgShadow">
                                 <div className='textBig textBold mb-1'>Reason for the DCN</div>
                                 <p className='textNormal'>{data.dcnReason}</p>
                              </Grid.Column>
                           </Grid>
                           <Grid>
                              <Grid.Column className="p-2 mt-1 bgWhite border-1 bgShadow">
                                 <div className='textBig textBold mb-1'>Restrictions</div>
                                 {
                                    data.dcnActionItemsVo.length === 0 ?
                                       <p className='textNormal'>There has been no monitoring yet</p>
                                       :
                                       // <ul className='px-1' style={{ fontSize: '1.12em', lineHeight: '1.32em' }}>
                                       <ul className='px-1 contentMedium'>
                                          {data.dcnActionItemsVo.map(item => <li className='mb-1'>{item.actionName}</li>)}
                                       </ul>
                                 }

                              </Grid.Column>
                           </Grid>
                        </Grid.Column>

                        <Grid.Column mobile={16} tablet={16} computer={10} className="m-1 p-2 bgWhite border-1 bgShadow">
                           <div className='flex flexJustifySpaceBetween mb-1'>
                              <div className='textBig textBold'>Dog Control Notices</div>
                              {/* <Button className='customBtn1Secondary' size='large'>Download PDF</Button> */}
                           </div>
                           <Tab
                              defaultActiveIndex={0}
                              menu={{ attached: 'top' }}
                              panes={[
                                 {
                                    menuItem: 'DCN', render: () =>
                                       <Tab.Pane attached='bottom'>
                                          <Grid columns='equal'>
                                             <Grid.Column className='' textAlign='right'>
                                                {data.dcnFileName && <Button className='customBtn1Secondary' loading={this.state.isDownloadingFile} onClick={() => this.downloadFile(data.dcnId, data.dcnFileName)}>Download DCN</Button>}
                                             </Grid.Column>
                                          </Grid>
                                          <PdfPreview
                                             refId={data.dcnId}
                                             fileName={data.dcnFileName}
                                          />
                                          {/* <PdfPreview url={window.location.protocol + '//' + window.location.host + "/scannedpdf/" + data.bpid + '/' + encodeURIComponent(data.dcnFileName)} /> */}
                                       </Tab.Pane>
                                 },
                                 {
                                    menuItem: 'Service of Notice', render: () =>
                                       <Tab.Pane attached='bottom'>
                                          <Grid columns='equal'>
                                             <Grid.Column className='' textAlign='right'>
                                                {data.sonFileName && <Button className='customBtn1Secondary' loading={this.state.isDownloadingFile} onClick={() => this.downloadFile(data.dcnId, data.sonFileName)}>Download Service of Notice</Button>}
                                             </Grid.Column>
                                          </Grid>
                                          {/* <PdfPreview url={window.location.protocol + '//' + window.location.host + "/scannedpdf/" + data.bpid + '/' + encodeURIComponent(data.sonFileName)} /> */}
                                          <PdfPreview
                                             refId={data.dcnId}
                                             fileName={data.sonFileName}
                                          />
                                       </Tab.Pane>
                                 },
                              ]}
                           />
                        </Grid.Column>
                     </Grid>



                     {/* Discharge DCN Modal */}
                     <Modal open={this.state.dischargeDcnModal} dimmer='inverted'>
                        <Modal.Content>
                           <div className='textBig colPrimary textBold mb-1'>Discharge DCN</div>
                           <Form>
                              <Form.Field required>
                                 <label>Date of Discharge</label>
                                 <div className="ui left icon input">
                                    <DatePicker
                                       id='dischargeDate' placeholderText={`DD/MM/YYYY`} selected={this.state.dischargeDate || null}
                                       onChange={(date) => this.setState({ dischargeDate: date })}
                                       dateFormat="dd/MM/yyyy" className="react-dp-custom"
                                       // onBlur={() => util.validateDateServed()}
                                       isClearable
                                       filterDate={(date) => date <= new Date()}
                                       strictParsing
                                    />
                                    <i aria-hidden="true" className="calendar outline icon"></i>
                                 </div>
                              </Form.Field>
                           </Form>
                        </Modal.Content>
                        <Modal.Actions>
                           <Button icon='cancel' className='customBtn1Secondary' onClick={() => this.setState({ dischargeDcnModal: false })}>Cancel</Button>
                           <Button className='customBtn1Primary' onClick={() => this.dischargeDcn()} loading={this.state.isDischarging}>Confirm</Button>
                        </Modal.Actions>
                     </Modal>

                     {/* Suspend DCN Modal */}
                     <Modal open={this.state.suspendDcnModal} dimmer='inverted'>
                        <Modal.Content>
                           <div className='textBig colPrimary textBold mb-1'>Suspend DCN</div>
                           <Form>
                              <Form.Field required>
                                 <label>Date of Suspension</label>
                                 <div className="ui left icon input">
                                    <DatePicker
                                       id='suspensionDate' placeholderText={`DD/MM/YYYY`} selected={this.state.suspensionDate || null}
                                       onChange={(date) => this.setState({ suspensionDate: date })}
                                       dateFormat="dd/MM/yyyy" className="react-dp-custom"
                                       // onBlur={() => util.validateDateServed()}
                                       isClearable
                                       filterDate={(date) => date <= new Date()}
                                       strictParsing
                                    />
                                    <i aria-hidden="true" className="calendar outline icon"></i>
                                 </div>
                              </Form.Field>
                           </Form>
                        </Modal.Content>
                        <Modal.Actions>
                           <Button icon='cancel' className='customBtn1Secondary' onClick={() => this.setState({ suspendDcnModal: false })}>Cancel</Button>
                           <Button className='customBtn1Primary' onClick={() => this.suspendDcn()} loading={this.state.isSuspending}>Confirm</Button>
                        </Modal.Actions>
                     </Modal>
                     {/* Reactivate DCN Modal */}
                     <Modal open={this.state.reactivateDcnModal} dimmer='inverted'>
                        <Modal.Content>
                           <div className='textBig colPrimary textBold mb-1'>Reactivate DCN</div>
                           <Form>
                              <Form.Field required>
                                 <label>Date of Reactivation</label>
                                 <div className="ui left icon input">
                                    <DatePicker
                                       id='reactivationDate' placeholderText={`DD/MM/YYYY`} selected={this.state.reactivationDate || null}
                                       onChange={(date) => this.setState({ reactivationDate: date })}
                                       dateFormat="dd/MM/yyyy" className="react-dp-custom"
                                       // onBlur={() => util.validateDateServed()}
                                       isClearable
                                       filterDate={(date) => date <= new Date()}
                                       strictParsing
                                    />
                                    <i aria-hidden="true" className="calendar outline icon"></i>
                                 </div>
                              </Form.Field>
                           </Form>
                        </Modal.Content>
                        <Modal.Actions>
                           <Button icon='cancel' className='customBtn1Secondary' onClick={() => this.setState({ reactivateDcnModal: false })}>Cancel</Button>
                           <Button className='customBtn1Primary' onClick={() => this.reactivateDcn()} loading={this.state.isReactivating}>Confirm</Button>
                        </Modal.Actions>
                     </Modal>

                     {/* Variation Modal */}
                     <Modal open={this.state.createVariationModal} dimmer='inverted'>
                        <Modal.Content>
                           <div className='textBig textBold mb-1'>Are you sure you want to create a variation?</div>
                        </Modal.Content>
                        <Modal.Actions>
                           <Button icon='cancel' className='customBtn1Secondary' onClick={() => this.setState({ createVariationModal: false })}>Cancel</Button>
                           <Button className='customBtn1Primary' onClick={() => this.createVariation()} loading={this.state.isUpdating}>Confirm</Button>
                        </Modal.Actions>
                     </Modal>

                     {/* Transfer Modal */}
                     <Modal size='large' open={this.state.transferDcnModal} dimmer='inverted'>
                        <Modal.Content>
                           <div className='textBold colPrimary textBig mb-5px'>Transfer DCN</div>
                           <Form size='small'>
                              <Form.Field fluid required>
                                 <label>Transfer To</label>
                                 <Select required fluid
                                    fluid search selection placeholder='Select Local Council'
                                    options={this.state.transferToBpidOptions} value={this.state.transferToBpid}
                                    onChange={(e, { value }) => { this.addressLookUpReset(); this.handleDropdownChange('transferToBpid', value) }}
                                    onBlur={() => this.validateTransferToBpid()}
                                 />
                                 {this.state.error.transferToBpid && <div className='errorText'>{this.state.error.transferToBpid}</div>}
                              </Form.Field>
                              <Form.Field>
                                 <label>Remarks</label>
                                 <TextArea rows={1} name='transferRemarks' placeholder="Remarks (max 1000 chars)" value={this.state.transferRemarks} onChange={(e) => this.handleTextChange(e)} fluid required onBlur={() => this.validateTransferRemarks()} />
                                 {this.state.error.transferRemarks && <div className='errorText'>{this.state.error.transferRemarks}</div>}
                              </Form.Field>
                              <Form.Group widths='equal'>
                                 <Form.Field required>
                                    <label>Proper Person's Forename</label>
                                    <Input name='transferPpForename' placeholder="Proper Person's Forename (max 50 chars)" value={this.state.transferPpForename} onChange={(e) => this.handleTextChange(e)} fluid required onBlur={() => this.validateTransferPersonForename()} />
                                    {this.state.error.transferPpForename && <div className='errorText'>{this.state.error.transferPpForename}</div>}
                                 </Form.Field>
                                 <Form.Field required>
                                    <label>Proper Person's Surname</label>
                                    <Input name='transferPpSurname' placeholder="Proper Person's Surname (max 50 chars)" value={this.state.transferPpSurname} onChange={(e) => this.handleTextChange(e)} fluid required onBlur={() => this.validateTransferPersonSurname()} />
                                    {this.state.error.transferPpSurname && <div className='errorText'>{this.state.error.transferPpSurname}</div>}
                                 </Form.Field>
                              </Form.Group>
                              <Form.Group widths='equal'>
                                 <Form.Field>
                                    <label>Date of birth</label>
                                    <div className="ui left icon input">
                                       <DatePicker
                                          id='transferPpDob' placeholderText={`DD/MM/YYYY`} selected={this.state.transferPpDob || null}
                                          onChange={(date) => this.setState({ transferPpDob: date })}
                                          onBlur={() => this.validateTransferPersonDob()}
                                          dateFormat="dd/MM/yyyy" className="react-dp-custom"
                                          isClearable
                                          strictParsing
                                       />
                                       <i aria-hidden="true" className="calendar outline icon"></i>
                                    </div>
                                 </Form.Field>
                                 <Form.Field>
                                 </Form.Field>
                              </Form.Group>


                              <Grid columns='equal'>
                                 <Grid.Column className=''>
                                    <div className='textBold colPrimary textNormal mb-5px mt-1'>Enter the new address</div>
                                    <Form.Field >
                                       <div className='textNormal mt-1'><Icon className='colPrimary' name='info circle' /> Note: Look up and select address. If address not found click enter manually.</div>
                                    </Form.Field>
                                    <Form.Field required>
                                       <label>Address Line 1</label>
                                       <TextArea readOnly={this.state.selectedMapObj} rows={1} name='transferPpAddressLine1' placeholder="Address Line 1 (max 2000 chars)" value={this.state.transferPpAddressLine1} onChange={(e) => this.handleTextChange(e)} fluid onBlur={() => this.validateTransferPersonAddressLine1()} />
                                       {this.state.error.transferPpAddressLine1 && <div className='errorText'>{this.state.error.transferPpAddressLine1}</div>}
                                    </Form.Field>
                                    <Form.Field>
                                       <label>Address Line 2</label>
                                       <TextArea readOnly={this.state.selectedMapObj} rows={1} name='transferPpAddressLine2' placeholder="Address Line 2 (max 2000 chars)" value={this.state.transferPpAddressLine2} onChange={(e) => this.handleTextChange(e)} onBlur={() => this.validateTransferPersonAddressLine2()} />
                                       {this.state.error.transferPpAddressLine2 && <div className='errorText'>{this.state.error.transferPpAddressLine2}</div>}
                                    </Form.Field>
                                    <Form.Field>
                                       <label>Address Line 3</label>
                                       <TextArea readOnly={this.state.selectedMapObj} rows={1} name='transferPpAddressLine3' placeholder="Address Line 3 (max 2000 chars)" value={this.state.transferPpAddressLine3} onChange={(e) => this.handleTextChange(e)} onBlur={() => this.validateTransferPersonAddressLine3()} />
                                       {this.state.error.transferPpAddressLine3 && <div className='errorText'>{this.state.error.transferPpAddressLine3}</div>}
                                    </Form.Field>
                                    <Form.Field required>
                                       <label>City/Town</label>
                                       <TextArea readOnly={this.state.selectedMapObj} rows={1} name='transferPpCity' placeholder="City/Town (max 2000 chars)" value={this.state.transferPpCity} onChange={(e) => this.handleTextChange(e)} fluid onBlur={() => this.validateTransferPersonCity()} />
                                       {this.state.error.transferPpCity && <div className='errorText'>{this.state.error.transferPpCity}</div>}
                                    </Form.Field>
                                    <Form.Group widths='equal'>
                                       <Form.Field required>
                                          <label>Post Code</label>
                                          <Input readOnly={this.state.selectedMapObj} name='transferPpPostCode' placeholder="Post Code (max 50 chars)" value={this.state.transferPpPostCode} onChange={(e) => this.handleTextChange(e)} fluid onBlur={() => this.validateTransferPersonPostCode()} />
                                          {this.state.error.transferPpPostCode && <div className='errorText'>{this.state.error.transferPpPostCode}</div>}
                                       </Form.Field>
                                       <Form.Field>
                                          <label>UPRN</label>
                                          <Input readOnly={this.state.selectedMapObj} name='transferPpUprn' placeholder="UPRN (max 50 chars)" value={this.state.transferPpUprn} onChange={(e) => this.handleTextChange(e)} fluid onBlur={() => this.validateTransferPersonUprn()} />
                                          {this.state.error.transferPpUprn && <div className='errorText'>{this.state.error.transferPpUprn}</div>}
                                       </Form.Field>
                                    </Form.Group>
                                    <Grid>
                                       <Grid.Column computer={16} className='mt-1' textAlign='right'>
                                          {this.state.hasLookedUpAddress && <Button className='customBtn1Secondary' onClick={(e) => { e.preventDefault(); this.addressLookUpReset() }}>Reset</Button>}
                                          {/* <Button className='customBtn1Primary mr-0' onClick={(e) => this.addressLookUp(e)}>Look Up</Button> */}
                                       </Grid.Column>
                                    </Grid>
                                 </Grid.Column>
                                 <Grid.Column stretched className='px-5px pr-1'>
                                    <Segment className=''>
                                       <div className='colPrimary textNormal textBold mb-5px'>Address Look Up</div>
                                       <Form size='small'>
                                          <Form.Group widths='equal'>
                                             <Form.Field>
                                                <Input name='lookupAddressText' placeholder="House No./ Street/ Town/ Postcode" value={this.state.lookupAddressText} onChange={(e) => this.handleTextChange(e)} />
                                             </Form.Field>
                                             <Form.Field width={8}>
                                                <Input name='lookupAddressUprn' placeholder="UPRN" value={this.state.lookupAddressUprn} onChange={(e) => this.handleTextChange(e)} />
                                             </Form.Field>
                                             <Form.Field width={2}>
                                                <Button icon='search' className='customBtn1Primary' onClick={(e) => this.addressLookUp(e)} />
                                             </Form.Field>
                                          </Form.Group>
                                       </Form>
                                       <Segment className='mt-5px p-0 bgGrey94' loading={this.state.isLookingUpAddress}>
                                          {!this.state.isLookingUpAddress && <AddressLookUpMap
                                             data={this.state.addressLookUpList}
                                             height='292px' width='100%' center={[56.4907, -4.2026]}
                                             zoom={2} scrollWheelZoom={false}
                                             selectedPoint={this.state.selectedMapObj}
                                          />}

                                          <AddressLookUp
                                             selectedPoint={this.state.selectedMapObj}
                                             data={this.state.addressLookUpList}
                                             handleAddressSelect={this.handleAddressSelect}
                                             addressLookUpReset={this.addressLookUpReset}
                                             hasLookedUpAddress={this.state.hasLookedUpAddress}
                                          />
                                       </Segment>
                                    </Segment>
                                 </Grid.Column>
                              </Grid>
                           </Form>
                        </Modal.Content>
                        <Modal.Actions>
                           <Button icon='cancel' className='customBtn1Secondary' onClick={() => this.setState({ transferDcnModal: false })}>Cancel</Button>
                           <Button icon='undo' className='customBtn1Secondary' onClick={() => this.initializeTranferForm(data)}>Reset</Button>
                           <Button className='customBtn1Primary' onClick={() => this.transferDcn()} loading={this.state.isTransferringDcn}>Transfer</Button>
                        </Modal.Actions>
                     </Modal>
                  </div>
               }
            </Segment>
         </>
      );

   }
}

export default withTranslation()(withRouter(DcnsDetailPage))

