import React, { Component } from 'react'
import { Segment } from 'semantic-ui-react'

export class AddressLookUp extends Component {
   constructor(props) {
      super(props)

      this.state = {
         data: props.data,
         selectedPoint: props.selectedPoint,
      }
   }


   componentDidUpdate(prevProps, prevState) {
      if (prevProps.data !== this.props.data || prevProps.selectedPoint !== this.props.selectedPoint) {
         this.setState({ data: this.props.data, selectedPoint: this.props.selectedPoint })
      }
   }

   render() {
      const { data } = this.state
      return (
         <>
            <div className='textNormal textBold mt-5px px-5px'>
               Showing {data && data.length || 0} results
            </div>
            <div style={{ maxHeight: '171px', minHeight: '168px', overflow: 'auto', padding: '1px 5px 0px 5px' }}>
               {data && data.map(item => {
                  return (
                     <Segment key={item.SEQUENCE} className={`p-5px my-5px fCard ${this.props.selectedPoint && this.props.selectedPoint.UPRN == item.UPRN && 'active'}`} onClick={() => this.props.handleAddressSelect(item)}>
                        <div className='textSmall colPrimary textBold'>
                           {item.ADDRESS_STRING}
                           <span className=''> ∙ UPRN {item.UPRN}</span>
                        </div>
                     </Segment>
                  )
               })}
            </div>
            {this.props.hasLookedUpAddress &&
               <div className='text-center'>
                  Address Not Found? <span className='customLink textBold' onClick={this.props.addressLookUpReset}>Enter Manually</span>
               </div>}
         </>
      )
   }
}

export default AddressLookUp
