import React from "react";
import { withRouter, Link } from "react-router-dom";
import utility from "../../commons/utility.js";
import ajax from "../../commons/agent.js";
import ErrorMessage from "../../commons/errorMessage.jsx";
import { Button, Input, Icon, Loader, Grid, Search, Breadcrumb, Message, Label, Checkbox } from "semantic-ui-react";
import DmpNavbar from "../dmpNavbar.jsx";
import { Table } from "antd";
import AdvSearch from "./AdvSearch.jsx";
import moment from "moment";
import PageHeader from "../PageHeader.jsx";
// import { JsonToCsv } from 'react-json-csv'

class DogControlNotices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productData: [],
      dataLength: 0,
      errorResponse: [],
      filteredInfo: null,
      sortedInfo: null,
      searchText: "",
      searchedColumn: "",
      currentDataCount: 0,
      loader: false,
      catsubcatFilter: null,
      categoryOptions: null,

      myBpidFilter: false,
    };
  }

  componentDidMount() {
    this.pageReload();
  }
  pageReload() {
    var self = this;
    utility.enterLoading(self);

    let query = {
      size: 10000,
      sort: [{ updatedAt: { order: "desc" }, createdAt: { order: "desc" } }],
      // "sort": [{ "dcnEffectiveDate": { "order": "desc" } }],
      query: {
        bool: {
          must: [],
        },
      },
    };

    if (this.state.myBpidFilter) {
      query.query.bool.must = [...query.query.bool.must, { terms: { "bpid.keyword": [utility.fetchUserBpid()] } }];
    }

    ajax.ElasticDcn.elasticdogcontrolnotice(query).end((err, response) => {
      utility.enterUnLoading(self);
      let temp = utility.handleErrors(err);
      if (!err && response) {
        let res = response.body.hits.hits.reduce((acc, item) => (acc = [...acc, item._source]), []);

        let breedFilterList = res.reduce((acc, item) => (acc = [...acc, item.dogBreed]), []);
        breedFilterList = Array.from(new Set(breedFilterList)).map((item) => ({
          text: item,
          value: item,
        }));

        res.forEach((item) => {
          // item.createdAt = item.createdAt && moment.utc(item.createdAt, 'YYYY-DD-MM HH:mm:ss').local().format("YYYY-MM-DD HH:mm")
          item.createdAt = item.createdAt && utility.formatDate(item.createdAt);
          item.dcnEffectiveDate = item.dcnEffectiveDate && moment.utc(item.dcnEffectiveDate).local().format("YYYY-MM-DD");
          item.dcnEffectiveDateFormatted = item.dcnEffectiveDate && moment.utc(item.dcnEffectiveDate).local().format("DD-MM-YYYY");
          //   item.dcnEffectiveDate = item.dcnEffectiveDate && moment.utc(item.dcnEffectiveDate).local().format("DD-MM-YYYY");
          // item.createdAt = item.createdAt && moment.utc(item.createdAt, 'DD-MM-YYYY HH:mm:ss').local().format("YYYY-MM-DD HH:mm")
          item.dogOwnerName = `${item.ppForename} ${item.ppSurname}`;
          item.dogOwnerAddress = `${item.ppAddressLine1 || ""} ${item.ppAddressLine2 || ""} ${item.ppAddressLine3 || ""} ${item.ppCity || ""} ${item.ppPostCode || ""}`;
        });

        self.setState({ productData: res, dataLength: res.length, breedFilterList }, () => {
          // this.setState(prevState => {
          //     prevState.productData.map(item => {
          //         // item.createdAt = item.createdAt && moment.utc(item.createdAt, 'YYYY-DD-MM HH:mm:ss').local().format("YYYY-MM-DD HH:mm")
          //         item.createdAt = item.createdAt && utility.formatDate(item.createdAt)
          //         item.dcnEffectiveDate = item.dcnEffectiveDate && moment.utc(item.dcnEffectiveDate).local().format("DD-MM-YYYY")
          //         // item.createdAt = item.createdAt && moment.utc(item.createdAt, 'DD-MM-YYYY HH:mm:ss').local().format("YYYY-MM-DD HH:mm")
          //         item.dogOwnerName = `${item.ppForename} ${item.ppSurname}`
          //         item.dogOwnerAddress = `${item.ppAddressLine1 || ''} ${item.ppAddressLine2 || ''} ${item.ppAddressLine3 || ''} ${item.ppCity || ''} ${item.ppPostCode || ''}`
          //     })
          // })
        });
      } else {
        this.setState({ errorResponse: temp });
      }
    });
  }

  //search start
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          size="small"
          className="customInput"
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <div>
          <Button icon="redo" content="Reset" size="small" compact className="customBtn1Secondary" style={{ width: "48%" }} onClick={() => this.handleReset(clearFilters)} />
          <Button
            icon="search"
            content="Search"
            size="small"
            compact
            className="customBtn1Primary"
            style={{ width: "50%", marginRight: "0" }}
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          />
        </div>
      </div>
    ),
    filterIcon: (filtered) => <Icon name="search" className={filtered ? "colPrimary" : ""} style={{ padding: "15px 0px" }} />,
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ""),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) => text,
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  //search end

  handleChange = (pagination, filters, sorter, currentDataSource) => {
    // console.log('Various parameters', pagination, filters, sorter);
    // console.log('Count : ', currentDataSource.currentDataSource.length);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };

  downloadAllDcns(e) {
    let self = this;
    self.setState({ downloadingAllDcns: true });
    var promise = new Promise((resolve, reject) => {
      ajax.DCNService.downloadAllDcns(self.state.myBpidFilter).end(async (err, response) => {
        self.setState({ downloadingAllDcns: false });
        let errJson = utility.handleErrors(err);
        if (!err && response) {
          //console.log(response)
          let msg = [];
          resolve(response);
        } else {
          utility.loadDisplayMessage(self, "error", "Failure.", errJson.errMessage);
        }
      });
    }).then((resolve) => {
      var headers = resolve.headers;
      var blob = new Blob([resolve.xhr.response], {
        type: headers["content-type"],
      });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = resolve.xhr.getResponseHeader("fileName");
      document.body.appendChild(link);
      link.click();
      this.setState({ btn1loading: false });
    });
  }

  render() {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};

    let fSmallText = { fontSize: ".84em" };
    let fLegend = { margin: "0px 5px" };
    const cellStyle = { color: "#666", padding: "8px 8px", fontSize: ".98em" };

    const filename = "DCNs_" + Date.now(),
      fields = {
        dcnReferenceNo: "DCNREFERENCENO",
        dogOwnerName: "DOG OWNER NAME",
        dogOwnerAddress: "DOG OWNER ADDRESS",
        dogName: "DOG NAME",
        dogBreed: "DOG BREED",
        createdAt: "CREATED AT",
        dcnStatus: "DCN STATUS",
        // "buyerOrganizationName2": "BUYER",
      },
      style = {
        padding: "0px 12px",
        fontFamily: "Roboto",
        fontSize: "0.92em",
        border: "none",
        height: "32px",
        lineHeight: "30px",
      },
      text = "Download";

    const columns = [
      {
        title: "Reference No",
        dataIndex: "dcnReferenceNo",
        key: "dcnReferenceNo",
        // sorter: (a, b) => (a.dcnReferenceNo * a.dcnReferenceNo) - (b.dcnReferenceNo * b.dcnReferenceNo),
        // sortOrder: sortedInfo.columnKey === 'dcnReferenceNo' && sortedInfo.order,
        ...this.getColumnSearchProps("dcnReferenceNo"),
        width: "16%",
        fixed: "left",
        ellipsis: true,
        render: (dcnReferenceNo, record) => {
          return {
            props: { style: { ...cellStyle } },
            children: (
              <>
                <Link className="colPrimary textBold" to={`/dashboard/dogControlNotices/dcnsdetailpage/${record.dcnId}`} title={dcnReferenceNo}>
                  {utility.elipses(dcnReferenceNo, 22)}
                </Link>
              </>
            ),
          };
        },
      },
      {
        title: "Proper Person's Name",
        dataIndex: "dogOwnerName",
        key: "dogOwnerName",
        sorter: (a, b) => a.dogOwnerName.localeCompare(b.dogOwnerName),
        sortOrder: sortedInfo.columnKey === "dogOwnerName" && sortedInfo.order,
        ...this.getColumnSearchProps("dogOwnerName"),
        width: "16%",
        // fixed: 'left',
        ellipsis: true,
        render: (dogOwnerName, record) => {
          return {
            props: { style: { ...cellStyle } },
            children: <>{dogOwnerName}</>,
          };
        },
      },
      {
        title: "Address",
        dataIndex: "dogOwnerAddress",
        key: "dogOwnerAddress",
        sorter: (a, b) => a.dogOwnerAddress.localeCompare(b.dogOwnerAddress),
        sortOrder: sortedInfo.columnKey === "dogOwnerAddress" && sortedInfo.order,
        ...this.getColumnSearchProps("dogOwnerAddress"),
        width: "20%",
        ellipsis: true,
        render: (dogOwnerAddress, record) => {
          return {
            props: { style: { ...cellStyle } },
            children: <>{dogOwnerAddress}</>,
          };
        },
      },
      {
        title: "Dog's name",
        dataIndex: "dogName",
        key: "dogName",
        sorter: (a, b) => a.dogName.localeCompare(b.dogName),
        sortOrder: sortedInfo.columnKey === "dogName" && sortedInfo.order,
        ...this.getColumnSearchProps("dogName"),
        ellipsis: true,
        width: "16%",
        render: (dogName, record) => {
          return {
            props: { style: { ...cellStyle } },
            children: <>{dogName}</>,
          };
        },
      },
      {
        title: "Dog Breed",
        dataIndex: "dogBreed",
        key: "dogBreed",
        ...this.getColumnSearchProps("dogBreed"),
        // filters: this.state.breedFilterList,
        // filterMultiple: true,
        // filteredValue: filteredInfo.dogBreed || null,
        // onFilter: (value, record) => record.dogBreed.indexOf(value) === 0,
        ellipsis: true,
        width: "14%",

        // width: '18%',
        render: (dogBreed, record) => {
          return {
            props: { style: { ...cellStyle } },
            children: <>{dogBreed}</>,
          };
        },
      },
      {
        title: "Served on",
        dataIndex: "dcnEffectiveDate",
        key: "dcnEffectiveDate",
        sorter: (a, b) => new Date(a.dcnEffectiveDate) - new Date(b.dcnEffectiveDate),
        sortOrder: sortedInfo.columnKey === "dcnEffectiveDate" && sortedInfo.order,
        width: "12%",
        render: (dcnEffectiveDate, record) => {
          return {
            props: { style: { ...cellStyle } },
            children: <>{record.dcnEffectiveDateFormatted}</>,
          };
        },
      },
      {
        title: "Status",
        dataIndex: "dcnStatus",
        key: "dcnStatus",
        width: "8%",
        filters: [
          { text: "Active", value: "Active" },
          { text: "Discharged", value: "Discharged" },
          { text: "Suspended", value: "Suspended" },
        ],
        filterMultiple: true,
        filteredValue: filteredInfo.dcnStatus || null,
        onFilter: (value, record) => record.dcnStatus.indexOf(value) === 0,

        fixed: "right",
        render: (dcnStatus, record) => {
          return {
            props: { style: { ...cellStyle } },
            children: (
              <>
                {dcnStatus === "Active" && (
                  <Label className="textThin fluid" horizontal color="green">
                    ACTIVE
                  </Label>
                )}
                {dcnStatus === "Discharged" && (
                  <Label className="textThin fluid" horizontal color="grey">
                    DISCHARGED
                  </Label>
                )}
                {dcnStatus === "Draft" && (
                  <Label className="textThin fluid" horizontal color="blue">
                    DRAFT
                  </Label>
                )}
                {dcnStatus === "Inactive" && (
                  <Label className="textThin fluid" horizontal color="grey">
                    INACTIVE
                  </Label>
                )}
                {dcnStatus === "Suspended" && (
                  <Label className="textThin fluid" horizontal color="red">
                    SUSPENDED
                  </Label>
                )}
                {dcnStatus === "Expired" && (
                  <Label className="textThin fluid" horizontal color="grey">
                    EXPIRED
                  </Label>
                )}
                {dcnStatus === "Auto updated" && (
                  <Label className="textThin fluid" horizontal color="teal">
                    AUTO UPDATED
                  </Label>
                )}
              </>
            ),
          };
        },
      },
    ];

    const orgCategory = this.props.fetchOrganizationCategory(utility.fetchUserBpid());

    return (
      <>
        <PageHeader />

        <div className="px-0 mb-0" style={{ minHeight: "82vh" }}>
          {this.state.errorResponse.errFlag === "Y" && (
            <Grid className="mx-0 px-0">
              <Grid.Column width={16} className="m-0 p-0">
                <ErrorMessage showServerError={this.state.errorResponse} />
              </Grid.Column>
            </Grid>
          )}

          <AdvSearch />

          <Grid className="mx-0 px-0">
            <Grid.Column width={16} className="mx-1 my-1 bgWhite bgShadow">
              <>
                <div
                  style={{
                    padding: "0px 0",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      display: "contents",
                      fontSize: "1.4em",
                      fontWeight: "500",
                    }}
                  >
                    Showing {this.state.currentDataCount} {this.state.currentDataCount !== this.state.dataLength && `of ${this.state.dataLength}`} DCNs{" "}
                  </span>
                  <div>
                    <span style={{ marginRight: "30px" }}>{/* <span style={fLegend}><Icon color='blue' name='flask' /> Evaluation Required </span> */}</span>

                    {/* {this.state.productData.length > 0 && <JsonToCsv data={this.state.productData} filename={filename} fields={fields} style={style} text={text} />} */}
                    {orgCategory === "Local Authority" && (
                      <span className="mr-1" title="Filter My Council DCN(s)">
                        <Checkbox label="My Council DCN(s)" toggle onChange={() => this.setState({ myBpidFilter: !this.state.myBpidFilter }, this.pageReload)} />
                      </span>
                    )}
                    <Button
                      disabled={this.state.loader}
                      icon="download"
                      content="Download"
                      className="customBtn1Secondary mr-1 mb-5px"
                      compact
                      size="small"
                      onClick={() => this.downloadAllDcns()}
                      loading={this.state.downloadingAllDcns}
                    />
                    <Button icon="redo" content="Reset Filters And Sorters" className="mr-0 customBtn1Secondary mb-5px" compact size="small" onClick={this.clearAll} />
                  </div>
                </div>

                <Table
                  size="middle"
                  columns={columns}
                  rowKey={(obj) => obj.dcnId}
                  dataSource={this.state.productData}
                  onChange={this.handleChange}
                  loading={{
                    indicator: (
                      <div>
                        <Loader active size="small" />
                      </div>
                    ),
                    spinning: this.state.loader,
                  }}
                  locale={{ emptyText: "No Data" }}
                  className="border-1 bgWhite scrollableTable"
                  // scroll={{ x: 'calc(600px + 50%)' }}
                  scroll={{ x: 1080 }}
                  rowClassName={(record, index) => (index % 2 === 0 ? "bgWhite" : "bgWhite")}
                  pagination={{
                    showTotal: (total, range) => this.state.currentDataCount !== total && this.setState({ currentDataCount: total }),
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    size: "default",
                    pageSizeOptions: utility.getAntdTablePageOptions(this.dataLength),
                  }}
                />
              </>
            </Grid.Column>
          </Grid>
        </div>
      </>
    );
  }
}

export default withRouter(DogControlNotices);
